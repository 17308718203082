import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Icons } from 'assets'

export const Feature: FC = () => {
  const navigate = useNavigate()
  return (
    <Card>
      <Button
        onClick={() => navigate('/voucher')}
      >
        <Icon
          src={Icons.coupon}
          alt="icon"
        />
        <Label>Rewards Catalogue</Label>
      </Button>
      <Button
        onClick={() => navigate('/reward')}
      >
        <Icon
          src={Icons.gift}
          alt="icon"
        />
        <Label>My Rewards</Label>
      </Button>
      <Button
        onClick={() => navigate('/history')}
      >
        <Icon
          src={Icons.history}
          alt="icon"
        />
        <Label>History</Label>
      </Button>
    </Card>
  )
}

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  gap: .4rem;
  margin: .5rem;
  padding: .3rem;
  border-radius: 1.5rem;
  background: rgba(255,255,255,.5);
  border: 1px solid #ddd;
`

const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .4rem;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 1.2rem;
  background: rgba(255,246,246,.5);
  border: 1px solid #ffe7e7;
  padding: .2rem;
`

const Icon = styled.img`
  width: 30%;
  aspect-ratio: 1;
`

const Label = styled.span`
  font-size: .7rem;
  font-weight: 700;
  text-align: center;
`
