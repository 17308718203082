import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'

import stores from 'stores'

import { App } from 'components'

import './styles.scss'

import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <StoreProvider store={stores}>
      <App />
    </StoreProvider>
  </Router>
)

reportWebVitals()
