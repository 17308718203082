import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick'
import styled from 'styled-components'

import { CLIENT_CODE } from 'constant'
import { Alert, Button, Confirm, Topbar } from 'components'
import { Voucher, useStoreActions, useStoreState } from 'stores'

import { useVoucherDetail } from './hooks'
import { FormStatus } from 'types'
import { isEmpty } from 'utils'

export const VoucherDetailPage: FC = () => {
  const { id } = useParams()
  const location = useLocation()

  const item: Voucher = location?.state?.item

  const [modal, setModal] = useState<boolean>(false)
  const [success, setSuccess] = useState<any>('')
  const [error, setError] = useState<string>('')

  const { submit } = useStoreActions(action => action.redeem)
  const { data: redeemData, status, error: redeemError } = useStoreState(state => state.redeem)

  const { data } = useVoucherDetail(id, CLIENT_CODE)

  const navigate = useNavigate()

  const voucher = item || data

  const redeem = () => {
    setModal(false)
    if (item?.source?.toLowerCase() === 'internal') {
      const data = {
        products: [{ id: item.id, qty: 1 }],
        source: item.source
      }
      submit(data)
    }
  }

  useEffect(() => {
    if (status === FormStatus.SUCCESS) {
      setSuccess(['Congratulations! You\'ve got the voucher!', <br />, 'Check it out in my reward menu and use it ASAP before it expires!'])
    }
    if (status === FormStatus.ERROR && !isEmpty(redeemError)) {
      setError(redeemError)
    }
  }, [status])

  const settings = {
    dots: false,
    infinite: voucher?.images?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <Slider {...settings}>
        {voucher?.images?.map((item, index) =>
          <Image
            key={index}
            src={item}
            alt="image"
          />
        )}
      </Slider>
      <Content>
        <Title>{voucher?.name}</Title>
        <Point>{voucher?.point?.toLocaleString('id-ID')} Points</Point>
        <Available>Available at: {voucher?.available_stores?.length > 0 ? voucher?.available_stores?.map(item => item.store_name).join(', ') : 'All Outlets'}</Available>
        {voucher?.note &&
          <Description
            dangerouslySetInnerHTML={{ __html: voucher?.note }}
          />
        }
        {voucher?.instructions &&
          <Description
            dangerouslySetInnerHTML={{ __html: voucher?.instructions }}
          />
        }
        {voucher?.terms &&
          <Description
            dangerouslySetInnerHTML={{ __html: voucher?.terms }}
          />
        }
      </Content>
      <Bottom>
        <Button
          label="Redeem"
          onClick={() => setModal(true)}
          loading={status === FormStatus.LOADING}
        />
      </Bottom>
      {!isEmpty(success) &&
        <Alert
          title="Redeem Voucher"
          message={success}
          onClick={() => setSuccess('')}
        />
      }
      {!isEmpty(error) &&
        <Alert
          title="Redeem Voucher Failed"
          message={error.includes('quantity') && error.includes('limit') ? ['Oops! Voucher Redemption Failed', <br />, 'It looks like you\'ve already claimed this voucher.'] : error}
          onClick={() => setError('')}
        />
      }
      {modal &&
        <Confirm
          title="Are you sure to redeem your point?"
          message={''}
          onConfirm={redeem}
          onCancel={() => setModal(false)}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: block;
  min-height: 100vh;
  min-height: 100dvh;
`

const Image = styled.img`
  width: 100%;
  aspect-ratio: 1;
`

const Content = styled.div`
  padding: 1rem;
  padding-bottom: 5rem;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 1px;
`

const Point = styled.div`
  font-size: 1rem;
  font-weight: 800;
  margin-top: .2rem;
  color: var(--primary-100);
`

const Available = styled.div`
  font-size: .9rem;
  font-weight: 500;
  margin-top: 1rem;
`

const Description = styled.div`
  font-size: .9rem;
  font-weight: 400;
  margin-top: 1rem;
`

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background: #fff;
  z-index: 2;
`
