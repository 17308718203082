import { useState } from 'react'

import { api } from 'utils'
import { FormStatus } from 'types'
import { CLIENT_CODE, SECRET_CODE } from 'constant'
import { AxiosError } from 'axios'

type UseSendVerificationCode = {
  status: FormStatus
  submit: (mobile_phone: string) => Promise<void>
  error: string
}

export const useSendVerificationCode = (): UseSendVerificationCode => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE)
  const [error, setError] = useState('')

  const submit = async (mobile_phone: string) => {
    try {
      setError('')
      setStatus(FormStatus.LOADING)
      const smsToken = await getSmsToken()
      console.log(smsToken)
      await sendVerificationCode(mobile_phone, smsToken)
      setStatus(FormStatus.SUCCESS)
    } catch (e) {
      if (e instanceof AxiosError) {
        setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        setStatus(FormStatus.ERROR)
      }
    } finally {
      setStatus(FormStatus.IDLE)
    }
  }

  return {
    status,
    submit,
    error,
  }
}

const getSmsToken = async (): Promise<string> => {
  try {
    interface Response {
      status: string
      status_code: number
      data: {
        sms_token: string
      }
    }
    const formData = new FormData()
    formData.append('client_code', CLIENT_CODE)
    formData.append('secret_code', SECRET_CODE)
    const res = await api.post<Response>('/sms-token',
      formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: formData => formData,
    })
    const { data } = res.data
    return data.sms_token
  } catch (e) {
    throw e
  }
}

const sendVerificationCode = async (mobile_phone: string, smsToken: string) => {
  try {
    const body = {
      client_code: CLIENT_CODE,
      mobile_phone,
      type: 'New Registration',
      sms_token: smsToken,
      channel: 'wa'
    }
    await api.post('/verification/phone/send', body)
  } catch (e) {
    throw e
  }
}
