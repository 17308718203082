import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Icons, Images } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'
import { Topbar } from 'components'

export const ProfilePage: FC = () => {
  const { fetchAllPromo } = useStoreActions(action => action.promo)
  const { promos } = useStoreState(state => state.promo)

  useEffect(() => {
    fetchAllPromo({
      page: 1,
      limit: 100,
      status: 'running',
      client_code: CLIENT_CODE
    })
  }, [fetchAllPromo])

  const navigate = useNavigate()

  console.log('PROMOS', promos)
  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <HeaderTitle>PROMOTION</HeaderTitle>
      <Card>
        <Header>
          <TitleWrapper>
            <TitleIcon
              src={Icons.promo}
              alt="icon"
            />
            <Title>Promo Bulan Puasa</Title>
          </TitleWrapper>
        </Header>
        <Content>
          <Banner
            src={Images.promoBanner}
            alt="banner"
          />
        </Content>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  display: block;
`

const HeaderTitle = styled.div`
  font-size: 1rem;
  font-weight: 800;
  padding: 1rem;
`

const Card = styled.div`
  margin: .5rem;
  padding: .3rem;
  border-radius: 1.5rem;
  background: white;
  border: 1px solid #ddd;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .4rem .6rem;
  margin-bottom: .2rem;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: .4rem;
`

const TitleIcon = styled.img`
  background: #fff6f6;
  border: 1px solid #ffe7e7;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: .2rem;
`

const Title = styled.span`
  font-size: .8rem;
  font-weight: 800;
  letter-spacing: 1px;
`

const Content = styled.div`
  display: flex;
  border-radius: 1.2rem;
  background: white;
  border: 1px solid #eee;
  overflow: hidden;
`

const Banner = styled.img`
  width: 100%;
  aspect-ratio: 1;
`
