import { ChangeEvent, FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import Compressor from 'compressorjs'

import { useStoreState } from 'stores'
import { Alert, Button, InputDate, InputPhone, InputText, Topbar } from 'components'

import { useEditProfile } from './hooks'
import { isEmpty } from 'utils'
import { Icons, Images } from 'assets'

export const EditProfilePage: FC = () => {
  const navigate = useNavigate()

  const { profile } = useStoreState(state => state.profile)

  const [photo, setPhoto] = useState<File>()
  const [preview, setPreview] = useState<string>(profile?.profile_picture || '')
  const [success, setSuccess] = useState<string>('')
  const [error, setError] = useState<string>('')

  const { submit, isLoading } = useEditProfile(setSuccess, setError)

  const EditProfileSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    mobile_phone: yup.string().required('Phone number is required'),
    gender: yup.string().matches(/^M|F$/, 'Gender is required'),
    birth_date: yup.string().required('Birth date is required')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      name: profile?.name || '',
      mobile_phone: profile?.phone?.mobile || '',
      gender: profile?.gender || '',
      birth_date: profile?.birth_date || ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: EditProfileSchema,
    onSubmit: values => submit({ ...values, profile_picture: photo })
  })

  const upload = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files as FileList
    setPhoto(files?.[0])
    new Compressor(files?.[0], {
      maxWidth: 1000,
      minHeight: 1000,
      success: res => {
        setPhoto(new File([res], `${new Date().getTime()}.${files[0].type.replace(/(.*)\//g, '')}`))
      },
      error: err => {
        console.log(err)
      }
    })
    setPreview(URL.createObjectURL(files?.[0]))
  }

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <Form onSubmit={handleSubmit}>
        <ProfilePhoto
          $image={preview || Images.collab}
        >
          <Upload
            type="file"
            accept=".jpg,.jpeg,.png|image/*;capture=camera"
            onChange={upload}
          />
          <EditPhoto
            src={Icons.edit}
            alt="icon"
          />
        </ProfilePhoto>
        <InputText
          name="name"
          label="Name"
          value={values.name}
          onChange={handleChange}
          error={errors.name}
        />
        <InputPhone
          name="mobile_phone"
          label="Phone Number"
          value={values.mobile_phone}
          onChange={e => handleChange('mobile_phone')(e.target.value.replace(/[+-\s]/g, ''))}
          error={errors.mobile_phone}
          readOnly
        />
        <GenderContainer>
          <LabelContainer>
            <Label>Gender</Label>
          </LabelContainer>
          <RadioGroup>
            <RadioContainer>
              <GenderOption
                id="male"
                value="M"
                checked={values.gender === 'M'}
                onChange={e => handleChange('gender')(e.target.value)}
                disabled={!isEmpty(profile?.gender)}
              />
              <label htmlFor="male">Male</label>
            </RadioContainer>
            <RadioContainer>
              <GenderOption
                id="female"
                value="F"
                checked={values.gender === 'F'}
                onChange={e => handleChange('gender')(e.target.value)}
                disabled={!isEmpty(profile?.gender)}
              />
              <label htmlFor="female">Female</label>
            </RadioContainer>
          </RadioGroup>
          <Error>{errors.gender}</Error>
        </GenderContainer>
        <InputDate
          label="Birth Date"
          value={values.birth_date}
          onChangeDate={date => handleChange('birth_date')(date)}
          error={errors.birth_date}
          disabled={!isEmpty(profile?.birth_date)}
        />
        <Button
          type="submit"
          label="Save"
          loading={isLoading}
        />
      </Form>
      {!isEmpty(success) &&
        <Alert
          title="Edit Profile"
          message={success}
          onClick={() => {
            setSuccess('')
            navigate(-1)
          }}
        />
      }
      {!isEmpty(error) &&
        <Alert
          title="Edit Profile Failed"
          message={error}
          onClick={() => setError('')}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  display: block;
  padding: 1rem;
`

const Form = styled.form`
  display: block;
`

const ProfilePhoto = styled.label<{ $image?: string }>`
  display: block;
  position: relative;
  margin: 1rem auto;
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  border: 1px solid #eee;
  background-image: url(${props => props.$image});
  background-color: #f5f5f5;
  background-size: cover;
  background-position: center;
`

const EditPhoto = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 1rem;
  pointer-events: auto;
  z-index: 2;
`

const Upload = styled.input`
  display: none;
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.label`
  display: block;
  margin-bottom: var(--token-08);
  color: var(--secondary-100);
  font-weight: var(--fw-medium);
`

const GenderContainer = styled.div`
  padding-bottom: var(--token-12);
`

const RadioGroup = styled.div`
  display: flex;
  gap: 2rem;
`

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
  margin: .5rem 0;
`

const GenderOption = styled.input.attrs({ type: 'radio', name: 'gender' })`
  accent-color: var(--primary-100);
  width: 1.2rem;
  height: 1.2rem;
`

const Error = styled.div`
  color: var(--red-dark);
  height: auto;
  opacity: 1;
  pointer-events: auto;
  overflow: visible;
  padding: var(--token-04) 0 0;
  font-size: var(--font-size-sm);
`