import { createStore, createTypedHooks, EasyPeasyConfig } from 'easy-peasy'

import { AuthModel, auth } from './auth'
import { ClientModel, client } from './client'
import { ProfileModel, profile } from './profile'
import { LuckyDrawModel, luckydraw } from './luckydraw'
import { EarningModel, earning } from './earning'
import { VoucherModel, voucher } from './voucher'
import { RedeemModel, redeem } from './redeem'
import { HistoryModel, history } from './history'
import { RewardModel, reward } from './reward'
import { PromoModel, promo } from './promo'
import { OutletModel, outlet } from './outlet'

export * from './auth'
export * from './client'
export * from './profile'
export * from './luckydraw'
export * from './earning'
export * from './voucher'
export * from './redeem'
export * from './history'
export * from './reward'
export * from './promo'
export * from './outlet'

export interface StoreModel {
  auth: AuthModel,
  client: ClientModel,
  profile: ProfileModel,
  luckydraw: LuckyDrawModel,
  earning: EarningModel,
  voucher: VoucherModel,
  redeem: RedeemModel,
  history: HistoryModel,
  reward: RewardModel,
  promo: PromoModel,
  outlet: OutletModel
}

const storeModel: StoreModel = {
  auth,
  client,
  profile,
  luckydraw,
  earning,
  voucher,
  redeem,
  history,
  reward,
  promo,
  outlet
}

const storeConfig: EasyPeasyConfig = {
  devTools: process.env.NODE_ENV !== 'production'
}

const store = createStore(storeModel, storeConfig)
const typedHooks = createTypedHooks<StoreModel>()

export const { useStoreActions, useStoreDispatch, useStoreState } = typedHooks
export default store
