import { useState, useCallback, useEffect } from 'react'

import { PromoItem } from 'stores'
import { FetchStatus } from 'types'
import { api } from 'utils'

export function usePromotionDetail(promotionId: string | undefined, clientCode: string) {
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.LOADING)
  const [data, setData] = useState<PromoItem | null>(null)

  const fetch = useCallback(async () => {
    type ResponseType = {
      status: string
      status_code: string
      data: PromoItem
    }

    try {
      setStatus(FetchStatus.LOADING);
      setStatus(FetchStatus.LOADED);
      const res = await api.get<ResponseType>(`/promotion/${promotionId}`, {
        params: {
          client_code: clientCode
        }
      })
      const { data } = res.data
      setData(data)
      setStatus(FetchStatus.LOADED)
    } catch (e) {
      setStatus(FetchStatus.ERROR)
    }
  }, [promotionId, clientCode])

  useEffect(() => {
    if (promotionId) fetch()
  }, [promotionId, fetch])

  return {
    status,
    data,
    refetch: fetch
  }
}
