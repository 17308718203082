import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Topbar } from 'components'
import { Icons } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'

export const ContactUsPage: FC = () => {
  const { fetchAllClient } = useStoreActions(action => action.client)
  const { clients } = useStoreState(state => state.client)

  useEffect(() => {
    fetchAllClient()
  }, [fetchAllClient])

  const CONTACT_NUMBER = clients.find(client => client.code === CLIENT_CODE)?.whatsapp_number?.replace(/[-+ ]/g, '') || ''
  const IG = clients.find(client => client.code === CLIENT_CODE)?.instagram_link || ''

  const navigate = useNavigate()

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <Title>CONTACT US</Title>
      <Link
        onClick={() => window.open(`https://wa.me/${CONTACT_NUMBER}`)}
      >
        <Whatsapp />
        <Label>Customer Service</Label>
      </Link>
      <Link
        onClick={() => window.open(IG)}
      >
        <Instagram />
        <Label>instagram.com/madcoco.id</Label>
      </Link>
      <Link
        onClick={() => window.open('https://www.tiktok.com/@madcoco.id')}
      >
        <Tiktok />
        <Label>tiktok.com/@madcoco.id</Label>
      </Link>
    </Container>
  )
}

const Container = styled.div`
  display: block;
  padding: 1rem;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const Link = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
  margin: 1rem auto;
  cursor: pointer;
`

const Whatsapp = styled.img.attrs({ src: Icons.whatsapp })`
  width: 2rem;
  height: 2rem;
`

const Instagram = styled.img.attrs({ src: Icons.instagram })`
  width: 2rem;
  height: 2rem;
`

const Tiktok = styled.img.attrs({ src: Icons.tiktok })`
  width: 2rem;
  height: 2rem;
`

const Label = styled.div`
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
`
