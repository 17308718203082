import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Icons, Images } from "assets";
import { Profile as ProfileType, useStoreActions, useStoreState } from "stores";

import { AuthStatus } from "types";
import { Button, Topbar } from "components";

interface Card {
  name: string;
  image: any;
}

export const SettingPage: FC = () => {
  const { checkAuth, loggedOut } = useStoreActions((action) => action.auth);
  const { isAuthenticated, authStatus } = useStoreState((state) => state.auth);

  const { fetchProfile } = useStoreActions((action) => action.profile);
  const { profile } = useStoreState((state) => state.profile);

  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    if (isAuthenticated) fetchProfile();
    else if (authStatus !== AuthStatus.LOADING) navigate("/login");
  }, [fetchProfile, isAuthenticated, authStatus, navigate]);

  const cardsList = [
    { name: "White", image: Images.cardWhite },
    { name: "Pink", image: Images.cardPink },
    { name: "Green", image: Images.cardGreen },
    { name: "Gold", image: Images.cardGold },
    { name: "Platinum", image: Images.cardPlatinum },
  ];

  const getCardImage = (profile: ProfileType, cardsList: Card[]) => {
    const card: Card | undefined = cardsList.find(
      (card) =>
        card.name?.toLowerCase() === profile?.card_type?.name?.toLowerCase()
    );
    return card ? card.image : cardsList[0].image;
  };

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <Header>
        <CardPhoto
          $image={profile?.profile_picture || getCardImage(profile, cardsList)}
          onClick={() => navigate("/card")}
        />
        {/* <ProfilePhoto $image={profile?.profile_picture || Images.collab} /> */}
        <Card>
          <Profile>
            <ProfileIcon src={Images.logo} />
            <ProfileName>{profile?.name}</ProfileName>
          </Profile>
          <CardDetail>
            <PointContainer>
              <Point>
                <PointIcon src={Icons.point} alt="icon" />
                <PointLabel>
                  Points
                  <PointValue>
                    {profile?.point?.redeem?.toLocaleString("id-ID") || 0}
                  </PointValue>
                </PointLabel>
              </Point>
            </PointContainer>
          </CardDetail>
        </Card>
      </Header>
      <SettingContainer>
        <SettingTitle>Settings</SettingTitle>
        <SettingLink onClick={() => navigate("/edit-profile")}>
          Edit Profile
        </SettingLink>
        <SettingLink onClick={() => navigate("/change-password")}>
          Change Password
        </SettingLink>
        <SettingTitle>Legal and Support</SettingTitle>
        <SettingLink onClick={() => navigate("/contact-us")}>
          Contact Us
        </SettingLink>
      </SettingContainer>
      <Logout>
        <Button
          label="LOGOUT"
          onClick={() => loggedOut(() => navigate("/login"))}
        />
      </Logout>
      <PoweredBy />
      <Loyalid />
    </Container>
  );
};

const Container = styled.div`
  display: block;
`;

const Header = styled.div`
  padding: 0.5rem;
  background: #eafff1;
  border-radius: 0 0 2rem 2rem;
`;
const Card = styled.div`
  margin-top: 2rem;
  padding: 0.3rem;
  border-radius: 1.5rem;
  background: var(--primary-100);
`;

const CardDetail = styled.div`
  display: flex;
  padding: 0.4rem;
  border-radius: 1.2rem;
  background: white;
  border: 1px solid #eee;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin: 0.5rem;
`;

const ProfilePhoto = styled.div<{ $image: string }>`
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  margin: 1rem auto;
  background-image: url(${(props) => props.$image});
  background-position: center;
  background-size: cover;
`;

const CardPhoto = styled.div<{ $image: string }>`
  width: 12rem;
  height: 8rem;
  border-radius: 1rem;
  margin: 1rem auto;
  background-image: url(${(props) => props.$image});
  background-position: center;
  background-size: cover;
`;

const ProfileIcon = styled.img`
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: 0.2rem;
`;

const ProfileName = styled.div`
  font-size: 1rem;
  font-weight: 800;
  color: #fff;
`;

const PointContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  padding: 0.2rem;
`;

const Point = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const PointIcon = styled.img`
  border: 1px solid #eee;
  border-radius: 0.5rem;
  height: 1.8rem;
  padding: 0.2rem;
`;

const PointLabel = styled.div`
  font-size: 0.6rem;
  font-weight: 700;
`;

const PointValue = styled.div`
  font-size: 0.9rem;
  font-weight: 800;
  color: var(--primary-100);
`;

const SettingContainer = styled.div`
  padding: 0.5rem 1.5rem;
`;

const SettingTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 3rem;
`;

const SettingLink = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 3rem;
  cursor: pointer;
`;

const Logout = styled.div`
  padding: 1rem;
`;

const PoweredBy = styled.div.attrs({ children: "Powered By" })`
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  margin-top: 2rem;
`;

const Loyalid = styled.img.attrs({ src: Images.loyalid, alt: "logo" })`
  display: block;
  height: 2rem;
  margin: 0.5rem auto 4rem auto;
`;
