import { FC, Fragment } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { Icons, Images } from 'assets'

interface Props {
  title?: string
  onBack?: () => void
}

export const Topbar: FC<Props> = ({ onBack }) => {
  return (
    <Fragment>
      {createPortal(
        <Container>
          <Header>
            <Back onClick={onBack}>
              <BackIcon
                src={Icons.back}
                alt="icon"
              />
            </Back>
            <Logo
              src={Images.logoText}
              alt="logo"
            />
          </Header>
        </Container>
        , document.body
      )}
      <Bottom />
    </Fragment>
  )
}

const Container = styled.div`
  display: block;
`

const Header = styled.div`
  position: fixed;
  width: 100%;
  max-width: 28rem;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: .5rem;
  background: #eafff1;
  z-index: 100;
  /* border-radius: 0 0 2rem 2rem; */
  /* border-bottom: 2px solid var(--primary-100); */
`

const Back = styled.div`
  padding: .5rem;
  cursor: pointer;
`

const BackIcon = styled.img`
  display: block;
  width: 2rem;
  height: 2rem;
`

const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: block;
  height: 1.5rem;
`

const Bottom = styled.div`
  height: 4rem;
`