import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { debounce } from "lodash";
import { getDistance } from "geolib";

import { InputText, Loader, Topbar } from "components";
import { OutletItem, useStoreActions, useStoreState } from "stores";
import { CLIENT_CODE } from "constant";
import { Icons } from "assets";
import { FetchStatus } from "types";
import { getStorageLocation } from "utils";

type CurrentLocation = {
  latitude: number;
  longitude: number;
};

export const OutletPage: FC = () => {
  const [keyword, setKeyword] = useState<string>("");
  const [currentLocation, setCurrentLocation] = useState<CurrentLocation>({
    latitude: 0,
    longitude: 0,
  });

  const { fetchAllOutlet } = useStoreActions((action) => action.outlet);
  const { outlets, status } = useStoreState((state) => state.outlet);

  useEffect(() => {
    fetchAllOutlet({
      page: 1,
      limit: 200,
      client_code: CLIENT_CODE,
      active: 1,
    });
  }, [fetchAllOutlet]);

  const searchOutlet = (s: string) => {
    fetchAllOutlet({
      page: 1,
      limit: 200,
      client_code: CLIENT_CODE,
      s,
      active: 1,
    });
  };

  const debouncedSearch = useCallback(debounce(searchOutlet, 500), []);

  useEffect(() => {
    if (keyword) debouncedSearch(keyword);
  }, [keyword]);

  const navigate = useNavigate();

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error get location: ", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  }, []);

  const calculateDistance = (from: OutletItem, to: CurrentLocation) => {
    if (!to) return 0;
    const dis = getDistance(
      { latitude: from.latitude, longitude: from.longitude },
      { latitude: to.latitude, longitude: to.longitude }
    );
    return dis / 1000;
  };

  useEffect(() => {
    const currentLocation = async () => {
      const location = await getStorageLocation();
      if (location) {
        const item = JSON.parse(location);
        setCurrentLocation({
          latitude: item.latitude,
          longitude: item.longitude,
        });
      } else {
        getLocation();
      }
    };
    currentLocation();
  }, []);

  const { latitude, longitude } = currentLocation;
  if (latitude !== 0 && longitude !== 0)
    outlets.sort(
      (a, b) =>
        calculateDistance(a, currentLocation) -
        calculateDistance(b, currentLocation)
    );

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <HeaderTitle>OUTLETS</HeaderTitle>
      {/* <Search>
        <InputText
          placeholder="Search outlet..."
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        {keyword && (
          <Button
            onClick={() => {
              setKeyword("");
              fetchAllOutlet({
                page: 1,
                limit: 100,
                client_code: CLIENT_CODE,
                active: 1,
              });
            }}
          >
            <Clear />
          </Button>
        )}
      </Search> */}
      <Grid>
        {outlets.map((item, index) => (
          <Card
            key={index}
            onClick={() => navigate("/outlet-detail", { state: { item } })}
          >
            {item?.image ? (
              <Banner $image={item?.image} />
            ) : (
              <Placeholder $image={Icons.store} />
            )}
            <Content>
              <Name>{item?.name}</Name>
            </Content>
          </Card>
        ))}
      </Grid>
      {status === FetchStatus.LOADING && <Loader />}
    </Container>
  );
};

const Container = styled.div`
  display: block;
`;

const HeaderTitle = styled.div`
  font-size: 1rem;
  font-weight: 800;
  padding: 1rem;
`;

const Search = styled.div`
  position: relative;
  padding: 0 1rem;
`;

const Button = styled.div`
  position: absolute;
  top: calc(50% - 0.3rem);
  right: 1.5rem;
  transform: translateY(-50%);
`;

const Clear = styled.img.attrs({ src: Icons.clear })`
  display: block;
  height: 1.5rem;
  opacity: 0.5;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 0.5rem;
  box-sizing: border-box;
`;

const Card = styled.div`
  position: relative;
  margin: 0.5rem;
  margin-top: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
  overflow: hidden;
  cursor: pointer;
`;

const Content = styled.div`
  padding: 0.5rem;
`;

const Banner = styled.div<{ $image: string }>`
  width: 100%;
  aspect-ratio: 1;
  background-image: url(${(props) => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Placeholder = styled.div<{ $image: string }>`
  width: 100%;
  aspect-ratio: 1;
  background-color: #ddd;
  background-image: url(${(props) => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5rem;
  opacity: 0.2;
`;

const Name = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
`;
