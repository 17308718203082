import { useState } from 'react'
import { AxiosError } from 'axios'

import { CLIENT_CODE } from 'constant'
import { AuthResponse } from 'stores'
import { FormStatus } from 'types'
import { api } from 'utils'

export const useConvertPoint = (
  setSuccess: (message: string) => void,
  setError: (error: string) => void
) => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE)
  const [data, setData] = useState<AuthResponse>()

  const convert = async (point: number) => {
    try {
      setStatus(FormStatus.LOADING)
      const body = {
        client_code: CLIENT_CODE,
        option: 'rd_to_ld',
        lucky_draw_point_amount: point
      }
      const res = await api.post('convert-point', body)
      setStatus(FormStatus.SUCCESS)
      setSuccess(res?.data?.message)
      setData(res?.data?.data)
    } catch (e) {
      if (e instanceof AxiosError) {
        setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        setStatus(FormStatus.ERROR)
      }
    } finally {
      setTimeout(() => setStatus(FormStatus.IDLE), 1)
    }
  }

  return { convert, status, data }
}
