import { FC, useEffect } from 'react'
import styled from 'styled-components'

import { Icons, Images } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'

export const Footer: FC = () => {
  const { fetchAllClient } = useStoreActions(action => action.client)
  const { clients } = useStoreState(state => state.client)

  useEffect(() => {
    fetchAllClient()
  }, [fetchAllClient])

  const CONTACT_NUMBER = clients.find(client => client.code === CLIENT_CODE)?.whatsapp_number?.replace(/[-+ ]/g, '') || ''
  const IG = clients.find(client => client.code === CLIENT_CODE)?.instagram_link || ''

  return (
    <Container>
      <Label>Contact Us</Label>
      <SocialMedia>
        <Button onClick={() => window.open(`https://wa.me/${CONTACT_NUMBER}`)}>
          <Whatsapp />
        </Button>
        <Button onClick={() => window.open(IG)}>
          <Instagram />
        </Button>
        <Button onClick={() => window.open('https://www.tiktok.com/@madcoco.id')}>
          <Tiktok />
        </Button>
      </SocialMedia>
      <Logo />
    </Container>
  )
}

const Container = styled.div`
  padding: 2rem 1rem;
`

const Label = styled.div`
  font-size: .8rem;
  font-weight: 600;
  text-align: center;
  color: var(--primary-100);
`

const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem auto;
`

const Button = styled.div`
  cursor: pointer;
`

const Whatsapp = styled.img.attrs({ src: Icons.whatsapp })`
  height: 2rem;
`

const Instagram = styled.img.attrs({ src: Icons.instagram })`
  height: 2rem;
`

const Tiktok = styled.img.attrs({ src: Icons.tiktok })`
  height: 2rem;
`

const Logo = styled.img.attrs({ src: Images.madcoco })`
  display: block;
  height: 1.2rem;
  margin: 0 auto;
`



