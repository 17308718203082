import { FC } from 'react'
import styled from 'styled-components'

interface Props {
  width?: number
  height?: number
}

export const Skeleton: FC<Props> = ({ width, height }) => {
  return (
    <Container
      $width={width}
      $height={height}
    />
  )
}

const Container = styled.div<{ $width?: number, $height?: number }>`
  width: ${props => props.$width || 160}px;
  height: ${props => props.$height || 16}px;
  background-color: rgba(0,0,0,.1);
  border-radius: 1rem;
  transform-origin: left center;
  opacity: 0;
  animation: skeleton 1s ease infinite;
`
