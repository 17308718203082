import { PaginationData } from 'types'
import { api } from 'utils'
import { CLIENT_CODE } from 'constant'

import { FetchAllCategoryParam, FetchAllVoucherParam, Voucher } from '.'

const fetchVoucher = async (
  params: FetchAllVoucherParam,
): Promise<PaginationData<Voucher>> => {
  try {
    const res = await api.get('redeem/product', {
      params: { ...params, client_code: CLIENT_CODE }
    })
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

const fetchCategory = async (
  params: FetchAllCategoryParam,
): Promise<PaginationData<Voucher>> => {
  try {
    const res = await api.get('master/redeem-product-category', {
      params: { ...params, client_code: CLIENT_CODE }
    })
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const redeemProductService = {
  fetchVoucher,
  fetchCategory
}