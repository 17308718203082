import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getDistance } from "geolib";

import { Icons } from "assets";
import { OutletItem, useStoreActions, useStoreState } from "stores";
import { CLIENT_CODE } from "constant";

type CurrentLocation = {
  latitude: number;
  longitude: number;
};

interface Props {
  currentLocation: CurrentLocation;
}

export const Outlet: FC<Props> = ({ currentLocation }) => {
  const navigate = useNavigate();

  const { fetchAllOutlet } = useStoreActions((action) => action.outlet);
  const { outlets } = useStoreState((state) => state.outlet);

  useEffect(() => {
    fetchAllOutlet({
      page: 1,
      limit: 200,
      client_code: CLIENT_CODE,
      active: 1,
    });
  }, [fetchAllOutlet]);

  const calculateDistance = (from: OutletItem, to: CurrentLocation) => {
    if (!to) return 0;
    const dis = getDistance(
      { latitude: from.latitude, longitude: from.longitude },
      { latitude: to.latitude, longitude: to.longitude }
    );
    return dis / 1000;
  };

  const { latitude, longitude } = currentLocation;
  if (latitude !== 0 && longitude !== 0)
    outlets.sort(
      (a, b) =>
        calculateDistance(a, currentLocation) -
        calculateDistance(b, currentLocation)
    );

  return (
    <Card>
      <Header>
        <TitleWrapper>
          <TitleIcon src={Icons.store} alt="icon" />
          <Title>OUTLETS</Title>
        </TitleWrapper>
        <SeeAll onClick={() => navigate("/outlet")}>See All »</SeeAll>
      </Header>
      {outlets?.length > 0 && (
        <Content>
          {outlets.slice(0, 10).map((item, index) => (
            <Button
              key={index}
              onClick={() => navigate("/outlet-detail", { state: { item } })}
            >
              {item.image ? (
                <Image style={{ backgroundImage: `url(${item.image}` }} />
              ) : (
                <Placeholder $image={Icons.store} />
              )}
              <LabelWrapper>
                <Label>{item.name}</Label>
              </LabelWrapper>
            </Button>
          ))}
        </Content>
      )}
    </Card>
  );
};

const Card = styled.div`
  margin: 0.5rem;
  padding: 0.3rem;
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.6rem;
  margin-bottom: 0.2rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const TitleIcon = styled.img`
  background: #fff6f6;
  border: 1px solid #ffe7e7;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: 0.2rem;
`;

const Title = styled.span`
  font-size: 0.8rem;
  font-weight: 800;
  letter-spacing: 1px;
`;

const SeeAll = styled.span`
  font-size: 0.8rem;
  font-weight: 800;
  letter-spacing: 1px;
  color: var(--primary-100);
`;

const Content = styled.div`
  display: flex;
  gap: 0.4rem;
  padding: 0 0.3rem;
  padding-bottom: 1rem;
  overflow: auto;
  margin: 0 -0.3rem;
`;

const Button = styled.div`
  width: calc(33.3vw - 0.85rem);
  max-width: 8.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
  overflow: hidden;
`;

const Image = styled.div`
  width: 100%;
  aspect-ratio: 1;
  background-size: cover;
  background-position: top;
`;

const Placeholder = styled.div<{ $image: string }>`
  width: 100%;
  aspect-ratio: 1;
  background-color: #ddd;
  background-image: url(${(props) => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5rem;
  opacity: 0.2;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  padding: 0.4rem;
  box-sizing: border-box;
  overflow: hidden;
`;

const Label = styled.div`
  font-size: 0.7rem;
  font-weight: 700;
  text-align: center;
  white-space: pre-wrap;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
