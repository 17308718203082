import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import styled from "styled-components";

import { Skeleton, Topbar } from "components";
import { useStoreActions, useStoreState } from "stores";

import { useBenefitCard } from "./hooks";
import { Icons, Images } from "assets";
import { FetchStatus } from "types";
import { isEmpty } from "utils";

export const BenefitCardPage: FC = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useStoreState((state) => state.auth);
  const { fetchProfile } = useStoreActions((action) => action.profile);
  const { profile, status } = useStoreState((state) => state.profile);
  const currentType = profile?.card_type?.name?.toLowerCase();
  const [initialIdx, setInitialIdx] = useState<number>(0);

  let level = 0;
  if (currentType === "green") level = 1;
  if (currentType === "gold") level = 2;
  if (currentType === "platinum") level = 3;

  const cards = useBenefitCard();

  const ref = useRef<Slider>(null);

  useEffect(() => {
    if (isAuthenticated) fetchProfile();
  }, [fetchProfile, isAuthenticated]);

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
  };

  useEffect(() => {
    let index = cards.data
      ?.sort((a, b) => a.id - b.id)
      .findIndex(
        (item) =>
          item?.name?.toLowerCase() === profile?.card_type?.name?.toLowerCase()
      );

    if (index == -1) index = 0;
    setInitialIdx(index);
  }, [profile, cards]);

  useEffect(() => {
    if (initialIdx) {
      ref.current?.slickGoTo(initialIdx);
    }
  }, [initialIdx]);

  const benefitCards = [
    { name: "White", image: Images.cardPink, color: "#BEBEBE" },
    { name: "Pink", image: Images.cardPink, color: "#FCCACB" },
    { name: "Green", image: Images.cardGreen, color: "#33725D" },
    { name: "Gold", image: Images.cardGold, color: "#D9B253" },
    { name: "Platinum", image: Images.cardPlatinum, color: "#525252" },
  ];

  return (
    <Fragment>
      <Topbar onBack={() => navigate(-1)} />
      <Content>
        <CardContainer>
          <Card>
            <CardTop>
              <Profile>
                <ProfileIcon src={Images.logo} />
                {status === FetchStatus.LOADING && !profile?.name ? (
                  <Skeleton width={120} />
                ) : (
                  <ProfileName>{profile?.name}</ProfileName>
                )}
              </Profile>
              <Badge>{profile?.card_type?.name}</Badge>
            </CardTop>
            <CardDetail>
              <PointContainer>
                <Point>
                  <PointIcon src={Icons.point} alt="icon" />
                  <PointLabel>
                    Points
                    {status === FetchStatus.LOADING &&
                      !profile?.point?.redeem ? (
                      <Skeleton width={50} />
                    ) : (
                      <PointValue>
                        {profile?.point?.redeem?.toLocaleString("id-ID") || 0}
                      </PointValue>
                    )}
                  </PointLabel>
                </Point>
              </PointContainer>
            </CardDetail>
          </Card>
        </CardContainer>
        {profile.card_type &&
          <MileStone>
            {cards.data.map((card, index) => (
              <Fragment key={index}>
                <CheckedContainer $color={benefitCards.find(item => item.name.toLowerCase() === card.name.toLowerCase())?.color}>
                  {level > index - 1 ? (
                    <Checked src={Icons.checked} />
                  ) : (
                    <Dot>
                      {level > index - 1 &&
                        <Checked src={Icons.checked} />
                      }
                    </Dot>
                  )}
                </CheckedContainer>
                {index < cards.data.length - 1 && <Line />}
              </Fragment>
            ))}
          </MileStone>
        }
        <Slider ref={ref} {...settings}>
          {cards.data
            ?.sort((a, b) => a.id - b.id)
            ?.map((item, index) => (
              <CardContainer key={index}>
                <img
                  style={{
                    width: "100%",
                    borderRadius: "1rem",
                    marginTop: "1rem",
                    border: "1px solid #ddd",
                  }}
                  src={item.image}
                  alt="card"
                />
                <Benefit key={index}>
                  {item.benefits.map((item, index) => (
                    <BenefitList key={index}>
                      <div>•</div>
                      <div>{item.value}</div>
                    </BenefitList>
                  ))}
                </Benefit>
              </CardContainer>
            ))}
        </Slider>
      </Content>
    </Fragment>
  );
};

const Content = styled.div`
  overflow: auto;
`;

const CardContainer = styled.div`
  padding: 0 0.5rem;
`;

const Card = styled.div`
  margin-top: 0.5rem;
  padding: 0.3rem;
  border-radius: 1.5rem;
  background: var(--primary-100);
`;

const CardTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardDetail = styled.div`
  display: flex;
  padding: 0.4rem;
  border-radius: 1.2rem;
  background: white;
  border: 1px solid #eee;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin: 0.5rem;
`;

const ProfileIcon = styled.img`
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: 0.2rem;
`;

const ProfileName = styled.div`
  font-size: 1rem;
  font-weight: 800;
  color: #fff;
`;

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  height: 1.5rem;
  padding: 0 5px;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.8rem;
  font-weight: 800;
  margin-right: 0.5rem;
`;

const PointContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  padding: 0.2rem;
`;

const Point = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const PointIcon = styled.img`
  border: 1px solid #eee;
  border-radius: 0.5rem;
  height: 1.8rem;
  padding: 0.2rem;
`;

const PointLabel = styled.div`
  font-size: 0.6rem;
  font-weight: 700;
`;

const PointValue = styled.div`
  font-size: 0.9rem;
  font-weight: 800;
  color: var(--primary-100);
`;

const Benefit = styled.div`
  padding: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  border: 1px solid #ddd;
  background-color: white;
  color: #333;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
`;

const BenefitList = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.5rem;
  color: #333;
`;

const MileStone = styled.div`
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
`;

const Checked = styled.img`
  filter: brightness(0) invert(1);
  height: 1rem;
`;

const CheckedContainer = styled.div<{ $color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${(props) => props.$color};
  border-radius: 100rem;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Line = styled.div`
  height: 0.05rem;
  background-color: #000000;
  flex-grow: 1;
`;

const Dot = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: white;
  border-radius: 50%;
`;
