import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'

import { Alert, Button, InputPassword, InputPhone } from 'components'
import { Images } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { FetchStatus } from 'types'
import { isEmpty } from 'utils'

export const LoginPage: FC = () => {
  const { checkAuth, login } = useStoreActions(action => action.auth)
  const { isAuthenticated, status, error: authError } = useStoreState(state => state.auth)

  const [error, setError] = useState<string>('')

  const navigate = useNavigate()

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  const SignInSchema = yup.object().shape({
    phone: yup.string().required('Phone Number is required'),
    password: yup.string().required('Password is required')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      phone: '',
      password: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: SignInSchema,
    onSubmit: values => login(values)
  })

  useEffect(() => {
    if (isAuthenticated && status !== FetchStatus.LOADING) {
      navigate('/home', { replace: true })
    }
  }, [isAuthenticated, status, navigate])

  useEffect(() => {
    if (!isEmpty(authError)) setError(authError)
  }, [authError])

  return (
    <Container>
      <Content>
        <Logo
          src={Images.logoText}
        />
        <Form onSubmit={handleSubmit}>
          <InputPhone
            name="phone"
            label="Phone Number"
            value={values.phone}
            onChange={e => handleChange('phone')(e.target.value.replace(/[+-\s]/g, ''))}
            error={errors.phone}
          />
          <InputPassword
            name="password"
            label="Password"
            value={values.password}
            onChange={handleChange}
            onForgot={() => navigate('/forgot-password')}
            error={errors.password}
          />
          <Button
            type="submit"
            label="Sign In"
            loading={status === FetchStatus.LOADING}
          />
        </Form>
        <SignUp>
          Don't have an account? <SignUpLink onClick={() => navigate('/register')}>Sign up.</SignUpLink>
        </SignUp>
      </Content>
      {!isEmpty(error) &&
        <Alert
          title="Login Failed"
          message={error}
          onClick={() => setError('')}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  display: block;
`

const Content = styled.div`
  width: 100%;
  padding: 1rem;
`

const Logo = styled.img`
  display: block;
  width: 90%;
  max-width: 15rem;
  margin: 3rem auto;
`

const Form = styled.form`
  display: block;
`

const SignUp = styled.div`
  margin: var(--token-24) auto;
  color: var(--grey-50);
  text-align: center;
`

const SignUpLink = styled.span`
  font-weight: 500;
  color: var(--primary-100);
  cursor: pointer;
`
