import { FC } from 'react'
import styled from 'styled-components'

import { Icons } from 'assets'

export const Loader: FC = () => {
  return (
    <Container>
      <Image
        src={Icons.loader}
        alt="loader"
      />
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, .2);
  z-index: 1000000000;
`

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3rem;
`
