import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Icons } from "assets";
import { OutletItem, PromoItem, useStoreActions, useStoreState } from "stores";
import { CLIENT_CODE } from "constant";
import { Topbar } from "components";
import { getDistance } from "geolib";
import { getStorageLocation } from "utils";

type CurrentLocation = {
  latitude: number;
  longitude: number;
};

export const PromoPage: FC = () => {
  const { fetchAllPromo } = useStoreActions((action) => action.promo);
  const { promos } = useStoreState((state) => state.promo);

  const { fetchAllOutlet } = useStoreActions((action) => action.outlet);
  const { outlets, status } = useStoreState((state) => state.outlet);

  const [filter, setFilter] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<CurrentLocation>({
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    fetchAllOutlet({
      page: 1,
      limit: 200,
      client_code: CLIENT_CODE,
      active: 1,
    });
  }, [fetchAllOutlet]);

  useEffect(() => {
    fetchAllPromo({
      page: 1,
      limit: 100,
      status: "running",
      client_code: CLIENT_CODE,
    });
  }, [fetchAllPromo]);

  const navigate = useNavigate();

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error get location: ", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  }, []);

  const calculateDistance = (from: PromoItem['store'], to: CurrentLocation) => {
    if (!to) return 0;
    const dis = getDistance(
      { latitude: from.latitude, longitude: from.longitude },
      { latitude: to.latitude, longitude: to.longitude }
    );
    return dis / 1000;
  };

  useEffect(() => {
    const currentLocation = async () => {
      const location = await getStorageLocation();
      if (location) {
        const item = JSON.parse(location);
        setCurrentLocation({
          latitude: item.latitude,
          longitude: item.longitude,
        });
      } else {
        getLocation();
      }
    };
    currentLocation();
  }, []);

  const { latitude, longitude } = currentLocation;
  if (latitude !== 0 && longitude !== 0) {
    outlets.sort(
      (a, b) =>
        calculateDistance(a, currentLocation) -
        calculateDistance(b, currentLocation)
    );

    promos.sort(
      (a, b) =>
        calculateDistance(a.store, currentLocation) -
        calculateDistance(b.store, currentLocation)
    );
  }

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <HeaderTitle>PROMOTION</HeaderTitle>
      {/* <div style={{ padding: '.5rem' }}>
        <select
          style={{
            width: '100%',
            border: '1px solid #ddd',
            borderRadius: '.5rem',
            padding: '.5rem',
            background: 'white'
          }}
          onChange={e => {
            const outlet = e.target.value
            setFilter(outlet)
            if (outlet) {
              fetchAllPromo({
                page: 1,
                limit: 100,
                status: 'all',
                client_code: CLIENT_CODE,
                outlet: Number(e.target.value)
              })
            } else {
              fetchAllPromo({
                page: 1,
                limit: 100,
                status: 'all',
                client_code: CLIENT_CODE
              })
            }
          }}
        >
          <option value="">All</option>
          {outlets.map((item, index) =>
            <option value={item.id} key={index}>{item.name}</option>
          )}
        </select>
      </div> */}
      <FilterContainer>
        <Filter onClick={() => setShowFilter(!showFilter)}>
          {filter ? filter : "All"}
        </Filter>
        {showFilter && (
          <Option>
            <OptionList
              onClick={() => {
                setShowFilter(false);
                setFilter("");
                fetchAllPromo({
                  page: 1,
                  limit: 100,
                  status: "running",
                  client_code: CLIENT_CODE,
                });
              }}
            >
              All
            </OptionList>
            {outlets.map((item, index) => (
              <OptionList
                key={index}
                onClick={() => {
                  setShowFilter(false);
                  setFilter(item.name);
                  fetchAllPromo({
                    page: 1,
                    limit: 100,
                    status: "running",
                    client_code: CLIENT_CODE,
                    outlet: item.id,
                  });
                }}
              >
                {item.name}
              </OptionList>
            ))}
          </Option>
        )}
      </FilterContainer>
      {promos
        ?.filter((item) => (filter ? item.store.name === filter : item))
        ?.map((item, index) => (
          <Card
            key={index}
            onClick={() => navigate(`${item.id}`, { state: { item } })}
          >
            <Header>
              <TitleWrapper>
                <TitleIcon src={Icons.promo} alt="icon" />
                <Title>{item?.name}</Title>
              </TitleWrapper>
            </Header>
            <Content>
              <Banner src={item?.photos?.[0]} alt="banner" />
            </Content>
          </Card>
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: block;
`;

const HeaderTitle = styled.div`
  font-size: 1rem;
  font-weight: 800;
  padding: 1rem;
`;

const Card = styled.div`
  margin: 0.5rem;
  padding: 0.3rem;
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.6rem;
  margin-bottom: 0.2rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const TitleIcon = styled.img`
  background: #fff6f6;
  border: 1px solid #ffe7e7;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: 0.2rem;
`;

const Title = styled.span`
  font-size: 0.8rem;
  font-weight: 800;
  letter-spacing: 1px;
`;

const Content = styled.div`
  display: flex;
  border-radius: 1.2rem;
  background: white;
  border: 1px solid #eee;
  overflow: hidden;
`;

const Banner = styled.img`
  width: 100%;
  aspect-ratio: 1;
`;

const FilterContainer = styled.div`
  position: relative;
  padding: 0.5rem;
`;

const Filter = styled.div`
  padding: 0.3rem 0.4rem;
  border: 1px solid #ddd;
  border-radius: var(--radius-xs);
  background-color: var(--surface);
  font-size: 0.8rem;
  color: var(--text-body);
  outline: none;
  transition: opacity 0.2s, color 0.2s, border 0.2s, box-shadow 0.2s,
    background-color 0.2s;

  &:hover {
    background: var(--grey-10);
    color: var(--text-body);
  }

  &:focus {
    border-color: var(--primary-80);
    box-shadow: var(--input-focus-shadow);
    background: var(--grey-10);
    color: var(--text-body);
  }
`;

const Option = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 1rem);
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  max-height: 20rem;
  overflow-y: auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 100;
`;

const OptionList = styled.div`
  padding: 0.5rem 0.8rem;
  font-size: 0.8rem;
  font-weight: 500;
  &:hover {
    background-color: var(--primary-100);
    color: white;
  }
`;
