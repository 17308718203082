import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Icons, Images } from 'assets'
import { Topbar } from 'components'
import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'

export const StampCollabPage: FC = () => {
  const { fetchAllClient } = useStoreActions(action => action.client)
  const { clients } = useStoreState(state => state.client)

  useEffect(() => {
    fetchAllClient()
  }, [fetchAllClient])

  const stamps = clients.filter(item => item.code !== CLIENT_CODE)

  const navigate = useNavigate()
  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <HeaderTitle>COLLECT STAMPS</HeaderTitle>
      {stamps.slice(0, 10).map((item, index) =>
        <Card
          key={index}
          onClick={() => navigate('/stamp', { state: { data: item } })}
        >
          <Header>
            <TitleWrapper>
              <TitleIcon
                src={Icons.stamp}
                alt="icon"
              />
              <Title>{item.name}</Title>
            </TitleWrapper>
          </Header>
          <Content>
            <Banner
              src={Images.collab}
              alt="banner"
            />
          </Content>
        </Card>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: block;
`

const HeaderTitle = styled.div`
  font-size: 1rem;
  font-weight: 800;
  padding: 1rem;
`

const Card = styled.div`
  margin: .5rem;
  padding: .3rem;
  border-radius: 1.5rem;
  background: rgba(255,255,255,.5);
  border: 1px solid #ddd;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .4rem .6rem;
  margin-bottom: .2rem;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: .4rem;
`

const TitleIcon = styled.img`
  background: #fff6f6;
  border: 1px solid #ffe7e7;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: .2rem;
`

const Title = styled.span`
  font-size: .8rem;
  font-weight: 800;
  letter-spacing: 1px;
`

const Content = styled.div`
  display: flex;
  border-radius: 1.2rem;
  background: white;
  border: 1px solid #eee;
  overflow: hidden;
`

const Banner = styled.img`
  width: 100%;
  aspect-ratio: 1;
`
