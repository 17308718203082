import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Icons, Images } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'

export const Stamp: FC = () => {
  const { fetchAllClient } = useStoreActions(action => action.client)
  const { clients } = useStoreState(state => state.client)

  useEffect(() => {
    fetchAllClient()
  }, [fetchAllClient])

  const stamps = clients.filter(item => item.code !== CLIENT_CODE)

  const navigate = useNavigate()
  return (
    <Card>
      <Header>
        <TitleWrapper>
          <TitleIcon
            src={Icons.stamp}
            alt="icon"
          />
          <Title>COLLECT STAMPS</Title>
        </TitleWrapper>
        <SeeAll
          onClick={() => navigate('/earn-stamp')}
        >
          See All »
        </SeeAll>
      </Header>
      {stamps?.length > 0 &&
        <Content>
          {stamps.slice(0, 10).map((item, index) =>
            <Button
              key={index}
              onClick={() => {
                navigate('/stamp', { state: { data: item } })
              }}
            >
              <Image
                style={{ backgroundImage: `url(${Images.collab}` }}
              />
              <LabelWrapper>
                <Label>{item.name}</Label>
              </LabelWrapper>
            </Button>
          )}
        </Content>
      }
    </Card>
  )
}

const Card = styled.div`
  margin: .5rem;
  padding: .3rem;
  border-radius: 1.5rem;
  background: rgba(255,255,255,.5);
  border: 1px solid #ddd;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .4rem .6rem;
  margin-bottom: .2rem;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: .4rem;
`

const TitleIcon = styled.img`
  background: #fff6f6;
  border: 1px solid #ffe7e7;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: .2rem;
`

const Title = styled.span`
  font-size: .8rem;
  font-weight: 800;
  letter-spacing: 1px;
`

const SeeAll = styled.span`
  font-size: .8rem;
  font-weight: 800;
  letter-spacing: 1px;
  color: var(--primary-100);
`

const Content = styled.div`
  display: flex;
  gap: .4rem;
  padding: 0 .3rem;
  padding-bottom: 1rem;
  overflow: auto;
  margin: 0 -.3rem;
`

const Button = styled.div`
  width: calc(33.3vw - .85rem);
  max-width: 8.5rem;
  flex-shrink: 0;
  border-radius: .5rem;
  background: white;
  border: 1px solid #ddd;
  overflow: hidden;
`

const Image = styled.div`
  width: 100%;
  aspect-ratio: 1;
  background-size: cover;
  background-position: top;
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  padding: .4rem;
  box-sizing: border-box;
  overflow: hidden;
`

const Label = styled.div`
  font-size: .7rem;
  font-weight: 700;
  text-align: center;
  white-space: pre-wrap;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

