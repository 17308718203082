export const Icons = {
  back: require("assets/icons/back.svg").default,
  chevronDown: require("assets/icons/chevron-down.svg").default,
  chevronLeft: require("assets/icons/chevron-left.svg").default,
  chevronRight: require("assets/icons/chevron-right.svg").default,
  chevronUp: require("assets/icons/chevron-up.svg").default,
  clear: require("assets/icons/clear.svg").default,
  close: require("assets/icons/close.svg").default,
  convert: require("assets/icons/convert.svg").default,
  coupon: require("assets/icons/coupon.svg").default,
  cs: require("assets/icons/cs.svg").default,
  edit: require("assets/icons/edit.svg").default,
  gift: require("assets/icons/gift.svg").default,
  history: require("assets/icons/history.svg").default,
  instagram: require("assets/icons/instagram.svg").default,
  right: require("assets/icons/right.svg").default,
  next: require("assets/icons/next.svg").default,
  pin: require("assets/icons/pin.svg").default,
  point: require("assets/icons/point.svg").default,
  previous: require("assets/icons/previous.svg").default,
  promo: require("assets/icons/promo.svg").default,
  qr: require("assets/icons/qr.svg").default,
  left: require("assets/icons/left.svg").default,
  loaderDotsWhite: require("assets/icons/loader-dots-white.svg").default,
  loader: require("assets/icons/loader.svg").default,
  loaderColor: require("assets/icons/loader-color.svg").default,
  location: require("assets/icons/location.svg").default,
  setting: require("assets/icons/setting.svg").default,
  stampFab: require("assets/icons/stamp-fab.svg").default,
  stamp: require("assets/icons/stamp.svg").default,
  store: require("assets/icons/store.svg").default,
  tiktok: require("assets/icons/tiktok.svg").default,
  user: require("assets/icons/user.svg").default,
  voucher: require("assets/icons/voucher.svg").default,
  whatsapp: require("assets/icons/whatsapp.svg").default,
  checked: require("assets/icons/checked.png"),
};

export const Images = {
  background: require("assets/images/background.jpg"),
  cardGold: require("assets/images/card-gold.jpeg"),
  cardGreen: require("assets/images/card-green.jpeg"),
  cardWhite: require("assets/images/card-white.jpg"),
  cardPink: require("assets/images/card-pink.jpeg"),
  cardPlatinum: require("assets/images/card-platinum.jpeg"),
  collabPomelo: require("assets/images/collab-pomelo.png"),
  collab: require("assets/images/collab.png"),
  logoText: require("assets/images/logo-text.svg").default,
  logo: require("assets/images/logo.svg").default,
  loyalid: require("assets/images/loyalid.png"),
  loyaltyCard: require("assets/images/loyalty-card.png"),
  madcoco: require("assets/images/madcoco.png"),
  outlet1: require("assets/images/outlet-1.jpeg"),
  outlet2: require("assets/images/outlet-2.jpeg"),
  outlet3: require("assets/images/outlet-3.jpeg"),
  promoBanner: require("assets/images/promo-banner.jpeg"),
  stamp: require("assets/images/stamp.svg").default,
};
