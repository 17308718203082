import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'

import { Alert, Button, InputPhone, Topbar } from 'components'
import { FormStatus } from 'types'

import { useForgotPassword } from './hooks'
import { isEmpty } from 'utils'

export const ForgotPasswordPage: FC = () => {
  const navigate = useNavigate()

  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')

  const { status, message, submit } = useForgotPassword({
    onError: (message: string) => setError(message)
  })

  const ForgotSchema = yup.object().shape({
    phone: yup.string().required('Phone Number is required')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      phone: ''
    },
    validationSchema: ForgotSchema,
    onSubmit: values => submit(values)
  })

  useEffect(() => {
    if (!isEmpty(message)) setSuccess(message)
  }, [message])

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <Title>Reset Password</Title>
      <Info>
        Enter the phone number associated with your account. We'll send the password recovery instruction.
      </Info>
      <Form onSubmit={handleSubmit}>
        <InputPhone
          name="phone"
          value={values.phone}
          onChange={e => handleChange('phone')(e.target.value.replace(/[+-\s]/g, ''))}
          error={errors.phone}
        />
        <Button
          type="submit"
          label="Send"
          loading={status === FormStatus.LOADING}
        />
      </Form>
      {!isEmpty(error) &&
        <Alert
          title="Reset Password Failed"
          message={error}
          onClick={() => setError('')}
        />
      }
      {!isEmpty(success) &&
        <Alert
          title="Reset Password"
          message={success}
          onClick={() => setSuccess('')}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  display: block;
  padding: 1rem;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const Info = styled.div`
  font-size: .8rem;
  font-weight: 400;
  margin-top: .5rem;
`

const Form = styled.form`
  margin-top: 1rem;
`