import { Images } from 'assets'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

export const Background: FC = () => {
  return (
    <Body />
  )
}

const Body = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh;
  height: 100lvh;
  max-width: 28rem;
  width: 100%;
  background-color: white;
  background-image: url(${Images.background});
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  user-select: none;
  pointer-events: none;
`
