import { action, thunk, computed } from 'easy-peasy'
import { AxiosError } from 'axios'

import { FetchStatus } from 'types'
import { isEmpty } from 'utils'

import { VoucherModel, redeemProductService } from '.'

export const voucher: VoucherModel = {
  paginator: {
    current_page: 1,
    next_page: 1,
    per_page: 15,
    previous_page: 1,
    total_items: 0,
    total_pages: 1,
  },
  category: [],
  voucher: [],
  status: FetchStatus.LOADING,
  error: '',
  isEmpty: computed(
    state => !isEmpty(state.voucher) && state.status === FetchStatus.LOADED
  ),

  // Actions
  setPaginator: action((state, payload) => {
    state.paginator = payload
  }),
  setData: action((state, payload) => {
    state.voucher = payload
  }),
  addData: action((state, payload) => {
    state.voucher = [...state.voucher, ...payload]
  }),
  setCategory: action((state, payload) => {
    state.category = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  fetchVoucher: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await redeemProductService.fetchVoucher(payload)
      action.setPaginator(res.paginator)
      action.setData(res.data)
      action.setStatus(FetchStatus.LOADED)
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.ERROR)
      }
    } finally {
      setTimeout(() => {
        action.setStatus(FetchStatus.IDLE)
        action.setError('')
      }, 10)
    }
  }),
  fetchMoreVoucher: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.FETCHING_MORE)
      const res = await redeemProductService.fetchVoucher(payload)
      action.setPaginator(res.paginator)
      action.addData(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.FETCHING_MORE_ERROR)
      }
    } finally {
      setTimeout(() => {
        action.setStatus(FetchStatus.IDLE)
        action.setError('')
      }, 10)
    }
  }),
  fetchCategory: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await redeemProductService.fetchCategory(payload)
      action.setCategory(res.data)
      action.setStatus(FetchStatus.LOADED)
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.ERROR)
      }
    } finally {
      setTimeout(() => {
        action.setStatus(FetchStatus.IDLE)
        action.setError('')
      }, 10)
    }
  }),
}
