import { useState } from 'react'

import { FormStatus } from 'types'
import { CLIENT_CODE } from 'constant'
import { api } from 'utils'

type UseForgotPasswordOption = {
  onError: (message: string) => void
}

export type ForgotPasswordDto = {
  phone: string
}

export const useForgotPassword = (callback?: UseForgotPasswordOption) => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE)
  const [message, setMessage] = useState<string>('')

  const submit = async (dto: ForgotPasswordDto) => {
    try {
      setStatus(FormStatus.LOADING)
      const body = {
        client_code: CLIENT_CODE,
        channel: 'wa',
        ...dto
      }
      const res = await api.post('/forgot-password', body)
      setStatus(FormStatus.SUCCESS)
      setMessage(res.data.message)
    } catch (e: any) {
      callback?.onError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
      setStatus(FormStatus.ERROR)
    } finally {
      setTimeout(() => {
        setStatus(FormStatus.IDLE)
        setMessage('')
      }, 1)
    }
  }

  return { status, message, submit }
}
