import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { QRFunc } from 'react-qrbtf'
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import moment from 'moment'
import styled from 'styled-components'

import { Button, Loader, Topbar } from 'components'
import { Reward, useStoreActions, useStoreState } from 'stores'
import { FetchStatus } from 'types'

export const RewardPage: FC = () => {
  const navigate = useNavigate()

  const [showBarcode, setShowBarcode] = useState<boolean>(false)
  const [voucher, setVoucher] = useState<Reward>()

  const { isAuthenticated } = useStoreState(state => state.auth)
  const { fetchUnused, fetchUsed, fetchMoreUnused, fetchMoreUsed } = useStoreActions(action => action.reward)
  const { unused, used, statusUnused, statusUsed, paginatorUnused, paginatorUsed } = useStoreState(state => state.reward)

  const limit = 20

  const getUnused = useCallback(() => {
    if (isAuthenticated)
      fetchUnused({
        paginator: {
          limit,
          page: 1,
        },
        status: 'not-used'
      })
  }, [isAuthenticated, fetchUnused])

  const getUsed = useCallback(() => {
    if (isAuthenticated)
      fetchUsed({
        paginator: {
          limit,
          page: 1,
        },
        status: 'used'
      })
  }, [isAuthenticated, fetchUsed])

  const loadMoreUnused = useCallback(() => {
    if (
      statusUnused === FetchStatus.LOADED &&
      paginatorUnused.current_page < paginatorUnused.total_pages
    ) {
      fetchMoreUnused({
        paginator: {
          page: paginatorUnused.current_page + 1,
          limit
        },
        status: 'not-used'
      })
    }
  }, [
    fetchMoreUnused,
    paginatorUnused.current_page,
    paginatorUnused.total_pages,
    statusUnused
  ])

  const loadMoreUsed = useCallback(() => {
    if (
      statusUsed === FetchStatus.LOADED &&
      paginatorUsed.current_page < paginatorUsed.total_pages
    ) {
      fetchMoreUsed({
        paginator: {
          page: paginatorUsed.current_page + 1,
          limit
        },
        status: 'used'
      })
    }
  }, [
    fetchMoreUsed,
    paginatorUsed.current_page,
    paginatorUsed.total_pages,
    statusUsed
  ])

  useEffect(() => {
    getUnused()
  }, [getUnused])

  useEffect(() => {
    getUsed()
  }, [getUsed])

  const [category, setCategory] = useState<string>('unused')

  const categories = [
    { label: 'Available Rewards', type: 'unused' },
    { label: 'Used', type: 'used' }
  ]

  const sheetRef = useRef<BottomSheetRef>(null)

  return (
    <Fragment>
      <Topbar onBack={() => navigate(-1)} />
      <Title>My Rewards</Title>
      {category === 'unused' ?
        <InfiniteScroll
          dataLength={unused?.length}
          next={loadMoreUnused}
          hasMore={paginatorUnused?.next_page > 1}
          loader={<LoadMore />}
        >
          <CategoryContainer>
            <Category>
              {categories?.map(item =>
                <CategoryButton
                  key={item.type}
                  $active={category === item.type}
                  onClick={() => {
                    setCategory(item.type)
                    if (item.type === 'used') getUsed()
                    else getUnused()
                  }}
                >
                  {item.label}
                </CategoryButton>
              )}
            </Category>
          </CategoryContainer>
          {statusUnused === FetchStatus.LOADING ?
            <Loader />
            :
            <History>
              {unused?.map((data, index) =>
                <Card key={index}>
                  <Content>
                    <Image
                      src={data?.product?.images?.[0]}
                      alt="image"
                    />
                    <Detail>
                      <Name>{data?.product?.name}</Name>
                      <Date>Valid until: {moment(data?.expired_at).format('DD MMM YYYY')}</Date>
                    </Detail>
                  </Content>
                  <ButtonContainer>
                    <Button
                      label="Show Code"
                      onClick={() => {
                        setVoucher(data)
                        setShowBarcode(true)
                      }}
                    />
                  </ButtonContainer>
                </Card>
              )}
            </History>
          }
          {unused?.length === 0 && statusUnused !== FetchStatus.LOADING &&
            <Empty>
              Data Empty
            </Empty>
          }
        </InfiniteScroll>
        :
        <InfiniteScroll
          dataLength={used?.length}
          next={loadMoreUsed}
          hasMore={paginatorUsed?.next_page > 1}
          loader={<LoadMore />}
        >
          <CategoryContainer>
            <Category>
              {categories?.map(item =>
                <CategoryButton
                  key={item.type}
                  $active={category === item.type}
                  onClick={() => {
                    setCategory(item.type)
                    if (item.type === 'used') getUsed()
                    else getUnused()
                  }}
                >
                  {item.label}
                </CategoryButton>
              )}
            </Category>
          </CategoryContainer>
          {statusUsed === FetchStatus.LOADING ?
            <Loader />
            :
            <History>
              {used?.map((data, index) =>
                <Card key={index}>
                  <Content>
                    <Image
                      src={data?.product?.images?.[0]}
                      alt="image"
                    />
                    <Detail>
                      <Name>{data?.product?.name}</Name>
                      <Date>Valid until: {moment(data?.expired_at).format('DD MMM YYYY')}</Date>
                    </Detail>
                  </Content>
                  <ButtonContainer>
                    <Button
                      label="Used"
                      onClick={() => {
                        setVoucher(data)
                        setShowBarcode(true)
                      }}
                    />
                  </ButtonContainer>
                </Card>
              )}
            </History>
          }
          {used?.length === 0 && statusUsed !== FetchStatus.LOADING &&
            <Empty>
              Data Empty
            </Empty>
          }
        </InfiniteScroll>
      }
      <BottomSheet
        open={showBarcode}
        ref={sheetRef}
        sibling={
          <div
            data-rsbs-backdrop="true"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setShowBarcode(false)
            }}
          />
        }
        blocking={false}
        onDismiss={() => setShowBarcode(false)}
        style={{ width: 100 }}
      >
        <BarcodeContainer>
          <Barcode>
            <QRFunc
              value={voucher?.serial_no}
              type="round"
              posType="round"
            />
          </Barcode>
          <BarcodeLabel>
            {voucher?.serial_no}
          </BarcodeLabel>
        </BarcodeContainer>
      </BottomSheet>
    </Fragment>
  )
}

const Title = styled.div`
  font-size: 1rem;
  font-weight: 800;
  padding: 1rem;
`

const CategoryContainer = styled.div`
  overflow: auto;
`

const Category = styled.div`
  display: flex;
  gap: .2rem;
  padding: .2rem;
  box-sizing: border-box;
  margin: 0 1rem;
  border-radius: 2rem;
  border: 1px solid #eee;
  overflow: auto;
`

const CategoryButton = styled.div<{ $active?: boolean }>`
  flex: 1;
  border: 1px solid ${props => props.$active ? 'var(--primary-100)' : '#ddd'};
  border-radius: 5rem;
  background-color: ${props => props.$active ? 'var(--primary-100)' : '#fff'};
  color: ${props => props.$active ? '#fff' : '#333'};
  font-size: 1rem;
  font-weight: 500;
  padding: .2rem 1rem;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
`

const History = styled.div`
  padding: 1rem;
`

const Card = styled.div`
  position: relative;
  margin: .5rem;
  margin-top: .5rem;
  box-sizing: border-box;
  border-radius: 1.8rem;
  background: rgba(255,255,255,.5);
  border: 1px solid #ddd;
  overflow: hidden;
  cursor: pointer;
`

const Name = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const Date = styled.div`
  font-size: .8rem;
  font-weight: 500;
  margin-top: .5rem;
`

const Content = styled.div`
  display: flex;
  padding: .5rem;
`

const Image = styled.img`
  width: 30%;
  flex-shrink: 0;
  border: 1px solid #eee;
  border-radius: 1.5rem;
  background-color: #f5f5f5;
`

const Detail = styled.div`
  flex: 1;
  padding: .5rem;
`

const ButtonContainer = styled.div`
  padding: .5rem;
  padding-top: 0;
`

const LoadMore = styled.div`
  text-align: center;
`

const Empty = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BarcodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Barcode = styled.div`
  height: 15rem;
`

const BarcodeLabel = styled.div`
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 2px;
  color: #333;
`
