import { FC } from 'react'
import moment, { MomentInput } from 'moment'
import styled from 'styled-components'

type DateType = Date | MomentInput | null

interface Props {
  date: DateType
  currentDate: DateType
  onClick: (date: DateType) => void
}

export const Days: FC<Props> = ({ date, currentDate, onClick }) => {
  return (
    <Day
      $active={moment().isSame(date, 'day')}
      $selected={moment(date).isSame(currentDate, 'day')}
      $muted={!moment(date).isSame(currentDate, 'month')}
      onClick={() => onClick(date)}
    >
      {moment(date).format('D')}
    </Day>
  )
}

const Day = styled.span<{
  $active: boolean,
  $selected: boolean,
  $muted: boolean
}>`
  width: 14.28571%;
  display: inline-block;
  text-align: center;
  user-select: none;
  margin: 5px 0;
  line-height: 25px;
  position: relative;
  font-size: 14px;
  font-weight: ${props => props.$active ? 800 : 500};
  background-color: ${props => props.$selected ? 'var(--primary-100)' : 'white'};
  color: ${props => props.$selected ? 'white' : props.$muted ? '#ddd' : props.$active ? 'var(--primary-100)' : '#333'};
  border-radius: 1rem;
`
