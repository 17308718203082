import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Topbar } from 'components'

import { OutletItem } from 'stores'
import { Icons } from 'assets'

export const OutletDetailPage: FC = () => {
  const location = useLocation()

  const outlet: OutletItem = location?.state?.item

  const navigate = useNavigate()

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      {outlet?.image ?
        <Banner
          src={outlet?.image}
          alt="banner"
        />
        :
        <Placeholder
          $image={Icons.store}
        />
      }
      <Content>
        <Title>{outlet?.name}</Title>
        <Unit>Unit: {outlet?.unit || '-'}</Unit>
        <Floor>Floor: {outlet?.floor || '-'}</Floor>
        <Note>{outlet?.note}</Note>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: block;
`

const Banner = styled.img`
  width: 100%;
  aspect-ratio: 1;
`

const Placeholder = styled.div<{ $image: string }>`
  width: 100%;
  aspect-ratio: 1;
  background-color: #ddd;
  background-image: url(${props => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5rem;
  opacity: .2;
`

const Content = styled.div`
  padding: 1rem;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 1px;
`

const Unit = styled.div`
  font-size: .8rem;
  font-weight: 400;
  margin: .5rem 0;
`

const Floor = styled.div`
  font-size: .8rem;
  font-weight: 400;
  margin: .5rem 0;
`

const Note = styled.div`
  font-size: .8rem;
  font-weight: 400;
  margin: .5rem 0;
  margin-top: 1rem;
`
