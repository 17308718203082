import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import styled from "styled-components";

import { Loader, Topbar } from "components";
import { useStoreActions, useStoreState } from "stores";
import { FetchStatus } from "types";

export const HistoryPage: FC = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useStoreState((state) => state.auth);
  const { fetchHistory, fetchMoreHistory } = useStoreActions(
    (action) => action.history
  );
  const { data, status, paginator } = useStoreState((state) => state.history);

  const [type, setType] = useState<string>("");

  const limit = 20;

  const getHistory = useCallback(
    (type: string) => {
      if (isAuthenticated)
        fetchHistory({
          page: 1,
          limit,
          type,
        });
    },
    [isAuthenticated, fetchHistory]
  );

  const fetchMore = useCallback(
    (type: string) => {
      if (
        status === FetchStatus.LOADED &&
        paginator.current_page < paginator.total_pages
      ) {
        fetchMoreHistory({
          page: paginator.current_page + 1,
          limit,
          type,
        });
      }
    },
    [fetchMoreHistory, paginator.current_page, paginator.total_pages, status]
  );

  useEffect(() => {
    getHistory(type);
  }, [getHistory, type]);

  const [category, setCategory] = useState<string>("");

  const categories = [
    { label: "All", type: "" },
    { label: "Earning", type: "earning" },
    { label: "Redeem", type: "redeem" },
  ];

  return (
    <InfiniteScroll
      dataLength={data?.length}
      next={() => fetchMore(type)}
      hasMore={paginator?.next_page > 1}
      loader={<LoadMore />}
    >
      <Topbar onBack={() => navigate(-1)} />
      <Title>History Point</Title>
      <CategoryContainer>
        <Category>
          {categories?.map((item) => (
            <CategoryButton
              key={item.type}
              $active={category === item.type}
              onClick={() => {
                setCategory(item.type);
                setType(item.type);
              }}
            >
              {item.label}
            </CategoryButton>
          ))}
        </Category>
      </CategoryContainer>
      {status === FetchStatus.LOADING ? (
        <Loader />
      ) : (
        <History>
          {data?.map((data, index) => (
            <Card key={index}>
              <Detail>
                <Type>{data?.type}</Type>
                <Date>{moment(data?.date).format("DD-MM-YYYY")}</Date>
              </Detail>
              <Point>{data?.point?.redeem?.toLocaleString("id-ID")}</Point>
              {data?.point?.redeem_expired_date ? (
                <Expired>
                  Expires at{" "}
                  {moment(data?.point?.redeem_expired_date).format(
                    "DD-MM-YYYY"
                  )}
                </Expired>
              ) : null}
            </Card>
          ))}
        </History>
      )}
      {data?.length === 0 && status !== FetchStatus.LOADING && (
        <Empty>Data Empty</Empty>
      )}
    </InfiniteScroll>
  );
};

const Title = styled.div`
  font-size: 1rem;
  font-weight: 800;
  padding: 1rem;
`;

const CategoryContainer = styled.div`
  overflow: auto;
`;

const Category = styled.div`
  display: flex;
  width: 100%;
  gap: 0.2rem;
  padding: 0 1rem;
  overflow: auto;
`;

const CategoryButton = styled.div<{ $active?: boolean }>`
  border: 1px solid
    ${(props) => (props.$active ? "var(--primary-100)" : "#ddd")};
  border-radius: 5rem;
  background-color: ${(props) =>
    props.$active ? "var(--primary-100)" : "#fff"};
  color: ${(props) => (props.$active ? "#fff" : "#333")};
  font-size: 1rem;
  font-weight: 500;
  padding: 0.2rem 1rem;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
`;

const History = styled.div`
  padding: 1rem;
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  position: relative;
`;

const Detail = styled.div`
  flex: 1;
`;

const Type = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
`;

const Date = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
`;

const Point = styled.div`
  font-size: 0.8rem;
  font-weight: 800;
`;

const LoadMore = styled.div`
  text-align: center;
`;

const Empty = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Expired = styled.div`
  color: #808080;
  font-size: 0.6rem;
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
`;
