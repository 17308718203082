import { ButtonHTMLAttributes, FC } from 'react'
import styled from 'styled-components'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string
  loading?: boolean
  disabled?: boolean
}

export const Button: FC<Props> = ({ label = 'OK', loading, disabled, ...props }) => {
  return (
    <Container
      data-loading={loading}
      disabled={disabled || loading}
      {...props}
    >
      {loading &&
        <Loading>
          <Spinner />
        </Loading>
      }
      <Label data-loading={loading}>
        {label}
      </Label>
    </Container>
  )
}

const Container = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: var(--btn-padding);
  border: none;
  border-radius: var(--radius-xl);
  background: transparent;
  font-weight: var(--fw-medium);
  text-align: center;
  white-space: nowrap;
  border: 1px solid var(--primary-100);
  background: var(--primary-100);
  color: var(--surface);
  cursor: pointer;
  transition: border-color .3s ease, background .3s ease, color .3s ease;

  &:hover {
    border-color: var(--primary-80);
    background: var(--primary-80);
    color: var(--surface);
  }

  &[disabled] {
    border-color: var(--primary-40);
    background: var(--primary-40);
    color: var(--surface);
  }
`

const Label = styled.span`
  font-weight: var(--fw-medium);
  text-align: center;
  white-space: nowrap;
  opacity: 1;
  transition: all .3s ease;
  &[data-loading=true] {
    opacity: 0;
    transform: scale(1.04) translateZ(0px);
  }
`

const Loading = styled.span`
  --size: 24px;
  --border-width: 2px;
  --border-color: var(--surface);
  --background-color: var(--grey-20);
  position: absolute;
  pointer-events: none;
`

const Spinner = styled.span`
  position: relative;
  display: flex;
  align-self: center;
  justify-self: center;
  width: var(--size);
  height: var(--size);
  animation: spin .5s linear infinite;
  &::before,&::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: var(--border-width) solid var(--background-color);
    border-radius: var(--radius-round);
  }
  &::after {
    border-color: transparent;
    border-top-color: var(--border-color);
    opacity: 1
  }
`
