import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'

import { Alert, Button, InputText, Topbar } from 'components'
import { FormStatus } from 'types'
import { isEmpty } from 'utils'

import { useRegisterNew, useSendVerificationCode } from './hooks'
import { useStoreActions, useStoreState } from 'stores'

export const OtpPage: FC = () => {
  const navigate = useNavigate()

  const { isAuthenticated } = useStoreState(state => state.auth)

  useEffect(() => {
    if (isAuthenticated) navigate('/home')
  }, [isAuthenticated, navigate])

  const location = useLocation()

  const data = location?.state?.data

  const { loggedIn } = useStoreActions(action => action.auth)

  const [token, setToken] = useState<string>('')
  const [timeLeft, setTimeLeft] = useState<number>(60)
  const [error, setError] = useState<string>('')

  const sendPhoneVerificationCode = useSendVerificationCode()
  const registerNew = useRegisterNew(setToken)

  useEffect(() => {
    if (sendPhoneVerificationCode.status === FormStatus.SUCCESS) {
      // otp send
    }

    if (sendPhoneVerificationCode.status === FormStatus.ERROR) {
      alert(sendPhoneVerificationCode.error)
    }
  }, [
    sendPhoneVerificationCode.status,
    sendPhoneVerificationCode.error
  ])

  useEffect(() => {
    if (registerNew.status === FormStatus.ERROR) {
      setError(registerNew.error)
    }
    if (registerNew.status === FormStatus.SUCCESS) {
      loggedIn(token)
      localStorage.setItem('MADCOCO_WELCOME_GIFT', 'true')
      navigate('/home', { replace: true })
    }
  }, [registerNew.status, registerNew.error, loggedIn, navigate, token])

  const sendCode = useCallback(() => {
    if (data?.mobile_phone) {
      sendPhoneVerificationCode.submit(data.mobile_phone)
      setTimeLeft(60)
    }
  }, [data?.mobile_phone, sendPhoneVerificationCode])

  useEffect(() => {
    sendCode()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (timeLeft === 0) setTimeLeft(0)
    if (!timeLeft) return

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  const OtpSchema = yup.object().shape({
    code: yup.string().required('Verification code is required')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: OtpSchema,
    onSubmit: values => {
      registerNew.submit({
        name: data.name,
        mobile_phone: data.mobile_phone,
        gender: data.gender,
        birth_date: data.birth_date,
        password: data.password,
        phone_verification_code: values.code
      })
    }
  })

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <Title>REGISTER</Title>
      <Info>Please enter the OTP code we sent to <strong>{data?.mobile_phone?.slice(0, -4)}****</strong></Info>
      <Form onSubmit={handleSubmit}>
        <InputText
          name="code"
          value={values.code}
          maxLength={4}
          onChange={handleChange}
          error={errors.code}
          style={{ textAlign: 'center' }}
        />
        <Button
          type="submit"
          label="SEND"
          loading={registerNew.status === FormStatus.LOADING}
        />
      </Form>
      {timeLeft === 0 ?
        <ResendButton
          onClick={() => sendCode()}
        >
          Resend New Code
        </ResendButton>
        :
        <Resend>
          Having trouble? Request a new code in 00:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}
        </Resend>
      }
      {!isEmpty(error) &&
        <Alert
          title="Register Failed"
          message={error}
          onClick={() => setError('')}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  display: block;
  padding: 1rem;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const Info = styled.div`
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin-top: 2rem;
`

const Form = styled.form`
  margin-top: 1rem;
`

const ResendButton = styled.div`
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin: 1rem auto;
  color: var(--primary-100);
`

const Resend = styled.div`
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin: 1rem auto;
`