import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Loader, Skeleton, Topbar } from "components";
import { useStoreActions, useStoreState, Voucher } from "stores";
import { FetchStatus } from "types";
import { isEmpty } from "utils";
import { Icons, Images } from "assets";

interface SortOption {
  name: string;
  value: string;
}

export const VoucherPage: FC = () => {
  const { fetchVoucher, fetchCategory } = useStoreActions(
    (action) => action.voucher
  );
  const {
    voucher,
    category: categories,
    status,
    error,
  } = useStoreState((state) => state.voucher);
  const sortOption: SortOption[] = [
    {
      name: "Lowest to Highest",
      value: "Ascending",
    },
    {
      name: "Highest to Lowest",
      value: "Descending",
    },
  ];

  const [category, setCategory] = useState<string>("all");
  const [sort, setSort] = useState<SortOption>(sortOption[0]);
  const [showSort, setShowsort] = useState<boolean>(false);

  const [sorted, setSorted] = useState<Voucher[]>(voucher);

  const { fetchProfile } = useStoreActions((action) => action.profile);

  const { profile, error: profileError } = useStoreState(
    (state) => state.profile
  );

  const limit = 100;

  useEffect(() => {
    fetchVoucher({
      type: "all",
      status: "available",
      source: "all",
      page: 1,
      limit,
    });
    fetchCategory({
      page: 1,
      limit: 50,
    });
    fetchProfile();
  }, [fetchVoucher, fetchCategory, fetchProfile]);

  useEffect(() => {
    if (status === FetchStatus.ERROR && !isEmpty(error)) console.log(error);
  }, [status, error]);

  useEffect(() => {
    let sortedArray = [...voucher];
    if (sort.value === "Descending") {
      sortedArray.sort((a, b) => b.point - a.point);
    }
    if (sort.value === "Ascending") {
      sortedArray.sort((a, b) => a.point - b.point);
    }
    setSorted(sortedArray);
  }, [sort, voucher]);

  const navigate = useNavigate();
  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <CardProfile onClick={() => navigate("/setting")}>
        <CardTop>
          <Profile>
            <ProfileIcon src={Images.logo} />
            {status === FetchStatus.LOADING && !profile?.name ? (
              <Skeleton width={120} />
            ) : (
              <ProfileName>{profile?.name}</ProfileName>
            )}
          </Profile>
          {profile?.card_type?.name && <Badge>{profile.card_type.name}</Badge>}
        </CardTop>
        <CardDetail>
          <PointContainer>
            <PointProfile>
              <PointIcon src={Icons.point} alt="icon" />
              <PointLabel>
                Points
                {status === FetchStatus.LOADING && !profile?.point?.redeem ? (
                  <Skeleton width={50} />
                ) : (
                  <PointValue>
                    {profile?.point?.redeem?.toLocaleString("id-ID") || 0}
                  </PointValue>
                )}
              </PointLabel>
            </PointProfile>
          </PointContainer>
        </CardDetail>
      </CardProfile>
      <SortContainer>
        <Sort onClick={() => setShowsort(!showSort)}>
          {sort.name ? sort.name : "Sort"}
        </Sort>
        {showSort && (
          <Option>
            {/* <OptionList
              onClick={() => {
                setShowsort(false);
                setSort({ name: "", value: "" });
              }}
            >
              Default
            </OptionList> */}
            {sortOption.map((item, index) => (
              <OptionList
                key={index}
                onClick={() => {
                  setShowsort(false);
                  setSort({ name: item.name, value: item.value });
                }}
              >
                {item.name}
              </OptionList>
            ))}
          </Option>
        )}
      </SortContainer>
      {/* <CategoryContainer>
        <Category>
          <CategoryButton
            $active={category === 'all'}
            onClick={() => setCategory('all')}
          >
            All
          </CategoryButton>
          {categories.map(item =>
            <CategoryButton
              key={item.id}
              $active={category === item.code}
              onClick={() => setCategory(item.code)}
            >
              {item?.name}
            </CategoryButton>
          )}
        </Category>
      </CategoryContainer> */}
      {status === FetchStatus.LOADING ? (
        <Loader />
      ) : (
        <Grid>
          {sorted
            .filter((item) =>
              category === "all"
                ? item
                : item.product_category.code === category
            )
            .map((item, index) => (
              <Card
                key={index}
                onClick={() => navigate(`${item.id}`, { state: { item } })}
              >
                <Image $image={item?.images?.[0]} />
                <Content>
                  <Name>{item?.name}</Name>
                  <Point>{item?.point?.toLocaleString("id-ID")} Points</Point>
                </Content>
              </Card>
            ))}
        </Grid>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100vh;
  height: 100dvh;
`;

const CategoryContainer = styled.div`
  overflow: auto;
  margin-top: 1rem;
`;

const Category = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  padding: 0 1rem;
  overflow: auto;
`;

const CategoryButton = styled.div<{ $active?: boolean }>`
  border: 1px solid
    ${(props) => (props.$active ? "var(--primary-100)" : "#ddd")};
  border-radius: 5rem;
  background-color: ${(props) =>
    props.$active ? "var(--primary-100)" : "#fff"};
  color: ${(props) => (props.$active ? "#fff" : "#333")};
  font-size: 1rem;
  font-weight: 500;
  padding: 0.2rem 1rem;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
`;

const Card = styled.div`
  position: relative;
  margin: 0.5rem;
  margin-top: 0.5rem;
  box-sizing: border-box;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
  overflow: hidden;
  cursor: pointer;
`;

const Name = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
`;

const Point = styled.div`
  font-size: 0.8rem;
  font-weight: 800;
  margin-top: 0.2rem;
`;

const Content = styled.div`
  padding: 0.5rem;
  padding-bottom: 1rem;
`;

const Image = styled.div<{ $image: string }>`
  width: 100%;
  aspect-ratio: 1;
  background-image: url(${(props) => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
const CardProfile = styled.div`
  margin: 0.5rem;
  padding: 0.3rem;
  border-radius: 1.5rem;
  background: var(--primary-100);
`;

const CardDetail = styled.div`
  display: flex;
  padding: 0.4rem;
  border-radius: 1.2rem;
  background: white;
  border: 1px solid #eee;
`;

const CardTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  height: 1.5rem;
  padding: 0 5px;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.8rem;
  font-weight: 800;
  margin-right: 0.5rem;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin: 0.5rem;
`;

const ProfileIcon = styled.img`
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: 0.2rem;
`;

const ProfileName = styled.div`
  font-size: 1rem;
  font-weight: 800;
  color: #fff;
`;

const PointContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  padding: 0.2rem;
`;

const PointProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const PointIcon = styled.img`
  border: 1px solid #eee;
  border-radius: 0.5rem;
  height: 1.8rem;
  padding: 0.2rem;
`;

const PointLabel = styled.div`
  font-size: 0.6rem;
  font-weight: 700;
`;

const PointValue = styled.div`
  font-size: 0.9rem;
  font-weight: 800;
  color: var(--primary-100);
`;

const SortContainer = styled.div`
  position: relative;
  padding: 0.5rem;
`;

const Sort = styled.div`
  padding: 0.3rem 0.4rem;
  border: 1px solid #ddd;
  border-radius: var(--radius-xs);
  background-color: var(--surface);
  font-size: 0.8rem;
  color: var(--text-body);
  outline: none;
  transition: opacity 0.2s, color 0.2s, border 0.2s, box-shadow 0.2s,
    background-color 0.2s;

  &:hover {
    background: var(--grey-10);
    color: var(--text-body);
  }

  &:focus {
    border-color: var(--primary-80);
    box-shadow: var(--input-focus-shadow);
    background: var(--grey-10);
    color: var(--text-body);
  }
`;

const Option = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 1rem);
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  max-height: 20rem;
  overflow-y: auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 100;
`;

const OptionList = styled.div`
  padding: 0.5rem 0.8rem;
  font-size: 0.8rem;
  font-weight: 500;
  &:hover {
    background-color: var(--primary-100);
    color: white;
  }
`;
