import { useState, useCallback, useEffect } from 'react'

import { Voucher } from 'stores'
import { FetchStatus } from 'types'
import { api } from 'utils'

export function useVoucherDetail(id: string | undefined, clientCode: string) {
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.LOADING)
  const [data, setData] = useState<Voucher | null>(null)

  const fetch = useCallback(async () => {
    type ResponseType = {
      status: string
      status_code: string
      data: Voucher
    }

    try {
      setStatus(FetchStatus.LOADING)
      const res = await api.get<ResponseType>(`/redeem/product/${id}`, {
        params: {
          client_code: clientCode
        }
      })
      const { data } = res.data
      setData(data)
      setStatus(FetchStatus.LOADED)
    } catch (e) {
      setStatus(FetchStatus.ERROR)
    }
  }, [id, clientCode])

  useEffect(() => {
    if (id) fetch()
  }, [id, fetch])

  return {
    status,
    data,
    refetch: fetch
  }
}
