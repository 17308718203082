import { FC, Fragment, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { Button } from 'components'

interface Props {
  title: string
  message: ReactNode
  onConfirm: () => void
  onCancel: () => void
}

export const Confirm: FC<Props> = ({ title, message, onConfirm, onCancel }) => {
  return (
    <Fragment>
      {createPortal(
        <Container>
          <MessageBox>
            <Title>{title}</Title>
            <Message>{message}</Message>
            <ButtonContainer>
              <Button
                label="Yes"
                onClick={onConfirm}
              />
              <Button
                label="Cancel"
                onClick={onCancel}
              />
            </ButtonContainer>
          </MessageBox>
        </Container>,
        document.body
      )}
    </Fragment>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  z-index: 100000000000;
`

const MessageBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  background-color: #fff;
  border-radius: 1rem;
  min-width: 15rem;
  max-width: 26rem;
  width: calc(100vw - 2rem);
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const Message = styled.div`
  font-size: .8rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: .5rem;
`