import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import forge from 'node-forge'
import styled from 'styled-components'

import { Icons, Images } from 'assets'
import { Topbar } from 'components'
import { PUBLIC_KEY } from 'constant'
import { useActivateCustomer } from './hooks'

export const StampPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { submit, data } = useActivateCustomer()

  const params = location?.state?.data

  useEffect(() => {
    if (params?.code) {
      const pki = forge.pki
      const pem = '-----BEGIN PUBLIC KEY-----\n' + PUBLIC_KEY + '-----END PUBLIC KEY-----'
      const publicKey = pki.publicKeyFromPem(pem)
      const code = params.code
      const encrypted = publicKey.encrypt(code, 'RSA-OAEP', {
        md: forge.md.sha1.create(),
        mgf: forge.mgf.mgf1.create(forge.md.sha1.create())
      })
      submit(forge.util.encode64(encrypted))
    }
    // eslint-disable-next-line
  }, [params])

  const [stamps, setStamps] = useState<number[]>([1])

  const stamp = (id: number) => {
    const index = stamps.findIndex(stamp => stamp === id)
    if (index === -1) stamps.push(id)
    else stamps.splice(index, 1)
    setStamps([...stamps])
  }

  console.log(data, stamp)

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <HeaderTitle>{params?.name}</HeaderTitle>
      <Card>
        <Header>
          <TitleWrapper>
            <TitleIcon
              src={Icons.stamp}
              alt="icon"
            />
            <Title>STAMPS</Title>
          </TitleWrapper>
        </Header>
        <Content>
          {[...Array(8)].map((_, index: number) =>
            <Button
              key={index}
            // onClick={() => stamp(index + 1)}
            >
              <Icon
                src={Images.stamp}
                alt="logo"
                $active={stamps.includes(index + 1)}
              />
            </Button>
          )}
        </Content>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  display: block;
`

const HeaderTitle = styled.div`
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  margin: 1rem auto;
`

const Card = styled.div`
  margin: .5rem;
  padding: .5rem;
  border-radius: 1.5rem;
  background: rgba(255,255,255,.5);
  border: 1px solid #ddd;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .2rem .4rem;
  margin-bottom: .2rem;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: .4rem;
`

const TitleIcon = styled.img`
  background: #fff6f6;
  border: 1px solid #ffe7e7;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: .2rem;
`

const Title = styled.span`
  font-size: .8rem;
  font-weight: 800;
  letter-spacing: 1px;
`


const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: .2rem;
  border-radius: 1.2rem;
  background: white;
  border: 1px solid #eee;
  padding: .2rem;
`

const Button = styled.div`
  width: calc(25% - .15rem);
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .4rem;
  border-radius: 1rem;
  background: white;
  /* border: 1px solid #eee; */
  padding: .8rem;
`

const Icon = styled.img<{ $active?: boolean }>`
  width: 100%;
  aspect-ratio: 1;
  filter: grayscale(${props => props.$active ? 0 : 1});
  opacity: ${props => props.$active ? 1 : .1};
  transition: all .3s ease;
`