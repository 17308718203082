import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'

import { Alert, Button, InputText, Topbar } from 'components'
import { useConvertPoint } from './hooks'
import { isEmpty } from 'utils'
import { FormStatus } from 'types'

export const ConvertPage: FC = () => {
  const [point, setPoint] = useState<number>(0)
  const [success, setSuccess] = useState<string>('')
  const [error, setError] = useState<string>('')

  const { convert, status } = useConvertPoint(setSuccess, setError)

  const navigate = useNavigate()
  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <Title>Convert Point</Title>
      <Card>
        <ConversionRate>
          <Label>Conversion Rate</Label>
          <LastUpdate>Last update at {moment().format('hh:mm')}</LastUpdate>
        </ConversionRate>
        <Rate>1 Point = 1 Draw</Rate>
      </Card>
      <Card>
        <InputText
          type="number"
          label="Point"
          value={point > 0 ? point : ''}
          onChange={e => setPoint(Number(e.target.value))}
        />
        <InputText
          label="Lucky Draw"
          value={point}
          readOnly
        />
        <Button
          label="Convert"
          loading={status === FormStatus.LOADING}
          onClick={() => convert(point)}
        />
      </Card>
      {!isEmpty(success) &&
        <Alert
          title="Convert Point"
          message={success}
          onClick={() => setSuccess('')}
        />
      }
      {!isEmpty(error) &&
        <Alert
          title="Convert Point Failed"
          message={error}
          onClick={() => setError('')}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  display: block;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 800;
  padding: 1rem;
`

const Card = styled.div`
  margin: .5rem;
  padding: 1rem;
  border-radius: 1.5rem;
  background: rgba(255,255,255,.5);
  border: 1px solid #ddd;
`

const ConversionRate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const LastUpdate = styled.div`
  font-size: .8rem;
  font-weight: 300;
`

const Rate = styled.div`
  font-size: .8rem;
  font-weight: 500;
  margin-top: .5rem;
`
