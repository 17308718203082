import { FC, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import moment, { MomentInput } from 'moment'
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import styled from 'styled-components'

import { Button } from 'components'

import { Calendar } from './components'

interface Props {
  label?: string
  value?: Date | MomentInput | null
  onChangeDate: (date: string) => void
  disabled?: boolean
  error?: string
}

export const InputDate: FC<Props> = ({ label, value, onChangeDate, disabled, error, ...props }) => {
  const ref = useRef<HTMLInputElement>(null)
  const sheetRef = useRef<BottomSheetRef>(null)

  useEffect(() => {
    if (error) ref.current?.setAttribute('data-error', 'true')
    else ref.current?.removeAttribute('data-error')
  }, [error])

  const [currentDate, setCurrentDate] = useState<string>(value ? moment(value).format('YYYY-MM-DD') : '')
  const [showCalendar, setShowCalendar] = useState<boolean>(false)

  useEffect(() => {
    if (value) moment(value).format('YYYY-MM-DD')
  }, [value])

  return (
    <Container>
      {label &&
        <LabelContainer>
          <Label>{label}</Label>
        </LabelContainer>
      }
      <Input
        ref={ref}
        onClick={() => !disabled && setShowCalendar(true)}
        {...props}
      >
        {value ? moment(value.toString(), 'YYYY-MM-DD').format('DD MMMM YYYY') : ''}
      </Input>
      {error &&
        <Error>
          {error}
        </Error>
      }
      {createPortal(
        <BottomSheet
          open={showCalendar}
          ref={sheetRef}
          sibling={
            <div
              data-rsbs-backdrop="true"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setShowCalendar(false)
              }}
            />
          }
          blocking={false}
          onDismiss={() => setShowCalendar(false)}
          style={{ width: 100 }}
        >
          <Calendar
            onClick={date => {
              setCurrentDate(moment(date).format('YYYY-MM-DD'))
            }}
            date={currentDate ? moment(currentDate.toString(), 'YYYY-MM-DD') : moment('1990-01-01', 'YYYY-MM-DD')}
          />
          <ButtonContainer>
            <Button
              label="Select"
              onClick={() => {
                onChangeDate(currentDate || moment().format('YYYY-MM-DD'))
                setShowCalendar(false)
              }}
            />
          </ButtonContainer>
        </BottomSheet>,
        document.body
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: var(--token-12);
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.label`
  display: block;
  margin-bottom: var(--token-08);
  color: var(--secondary-100);
  font-weight: var(--fw-medium);
`

const Input = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 42px;
  padding: var(--input-padding);
  border: var(--border-width) solid var(--grey-30);
  border-radius: var(--radius-xs);
  background-color: var(--surface);
  color: var(--text-body);
  outline: none;
  transition: opacity .2s, color .2s, border .2s, box-shadow .2s, background-color .2s;

  &:hover {
    background: var(--grey-10);
    color: var(--text-body);
  }

  &:focus {
    border-color: var(--primary-80);
    box-shadow: var(--input-focus-shadow);
    background: var(--grey-10);
    color: var(--text-body);
  }

  &[data-error=true] {
    border-color: var(--red-medium);
    background: var(--red-lightest);
    color: var(--red-dark);
    box-shadow: var(--input-error-shadow);
  }
`

const ButtonContainer = styled.div`
  padding: 1rem;
`

const Error = styled.div`
  color: var(--red-dark);
  height: auto;
  opacity: 1;
  pointer-events: auto;
  overflow: visible;
  padding: var(--token-04) 0 0;
  font-size: var(--font-size-sm);
`
