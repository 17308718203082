import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider, { Settings } from 'react-slick'
import styled from 'styled-components'

import { Icons } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'

export const Promotion: FC = () => {
  const { fetchAllPromo } = useStoreActions(action => action.promo)
  const { promos } = useStoreState(state => state.promo)

  useEffect(() => {
    fetchAllPromo({
      page: 1,
      limit: 100,
      status: 'running',
      client_code: CLIENT_CODE
    })
  }, [fetchAllPromo])

  const navigate = useNavigate()

  const settings: Settings = {
    dots: false,
    infinite: promos?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false
  }

  return (
    <Card>
      <Header>
        <TitleWrapper>
          <TitleIcon
            src={Icons.promo}
            alt="icon"
          />
          <Title>PROMOTIONS</Title>
        </TitleWrapper>
        <SeeAll
          onClick={() => navigate('/promo')}
        >
          See All »
        </SeeAll>
      </Header>
      {promos?.length > 0 &&
        <Content>
          <Slider {...settings}>
            {promos.map((item, index) =>
              <Banner
                key={index}
                $image={item?.photos?.[0]}
                onClick={() => navigate(`/promo/${item.id}`, { state: { item } })}
              />
            )}
          </Slider>
        </Content>
      }
    </Card>
  )
}

const Card = styled.div`
  margin: .5rem;
  padding: .3rem;
  border-radius: 1.5rem;
  background: rgba(255,255,255,.5);
  border: 1px solid #ddd;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .4rem .6rem;
  margin-bottom: .2rem;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: .4rem;
`

const TitleIcon = styled.img`
  background: #fff6f6;
  border: 1px solid #ffe7e7;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: .2rem;
`

const Title = styled.span`
  font-size: .8rem;
  font-weight: 800;
  letter-spacing: 1px;
`

const SeeAll = styled.span`
  font-size: .8rem;
  font-weight: 800;
  letter-spacing: 1px;
  color: var(--primary-100);
`

const Content = styled.div`
  border-radius: 1.2rem;
  background: white;
  border: 1px solid #eee;
  overflow: hidden;
`

const Banner = styled.div<{ $image: string }>`
  display: block !important;
  width: 100%;
  aspect-ratio: 1;
  background-image: url(${props => props.$image});
  background-position: center;
  background-size: cover;
`
