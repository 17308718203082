import { FC, useEffect, useState } from 'react'
import moment, { MomentInput } from 'moment'
import styled from 'styled-components'

import { Days } from '.'
import { Icons } from 'assets'

type DateType = Date | MomentInput | null

interface Props {
  date?: Date | MomentInput | null
  onClick: (date: DateType) => void
}

export const Calendar: FC<Props> = ({ date, onClick }) => {
  const [currentDate, setCurrentDate] = useState<DateType>(moment())

  useEffect(() => {
    setCurrentDate(date)
  }, [date])

  const thisDate = moment(currentDate)
  const daysInMonth = moment(currentDate).daysInMonth()
  const firstDayDate = moment(currentDate).startOf('month')
  const previousMonth = moment(currentDate).subtract(1, 'month')
  const previousMonthDays = previousMonth.daysInMonth()
  const nextsMonth = moment(currentDate).add(1, 'month')
  const days = []
  const labels = []

  const changeYear = (year: number) => {
    const newDate = moment(currentDate).year(year)
    setCurrentDate(newDate)
  }

  const changeMonth = (month: number) => {
    const newDate = moment(currentDate).month(month)
    setCurrentDate(newDate)
  }

  for (let i = 0; i < 7; i++) {
    labels.push(
      <DayName
        key={i}
      >
        {moment().day(i).format('dd')}
      </DayName>
    )
  }

  for (let i = firstDayDate.day() + 1; i > 1; i--) {
    previousMonth.date((previousMonthDays - i) + 2)
    days.push(
      <Days
        key={moment(previousMonth).format('DD MM YYYY')}
        onClick={onClick}
        currentDate={currentDate}
        date={moment(previousMonth)}
      />
    )
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i)
    days.push(
      <Days
        key={moment(thisDate).format('DD MM YYYY')}
        onClick={onClick}
        currentDate={currentDate}
        date={moment(thisDate)}
      />
    )
  }

  const daysCount = days.length
  for (let i = 1; i <= (42 - daysCount); i++) {
    nextsMonth.date(i)
    days.push(
      <Days
        key={moment(nextsMonth).format('DD MM YYYY')}
        onClick={onClick}
        currentDate={currentDate}
        date={moment(nextsMonth)}
      />
    )
  }

  return (
    <Container>
      <NavContainer>
        <Nav>
          <YearNav
            src={Icons.left}
            alt="icon"
            onClick={() => changeYear(moment(currentDate).year() - 1)}
          />
          <NavIcon
            src={Icons.previous}
            alt="icon"
            onClick={() => changeMonth(moment(currentDate).month() - 1)}
          />
        </Nav>
        <CurrentDate>
          {moment(currentDate).format('MMM YYYY')}
        </CurrentDate>
        <Nav>
          <NavIcon
            src={Icons.next}
            alt="icon"
            onClick={() => changeMonth(moment(currentDate).month() + 1)}
          />
          <YearNav
            src={Icons.right}
            alt="icon"
            onClick={() => changeYear(moment(currentDate).year() + 1)}
          />
        </Nav>
      </NavContainer>
      <Header>
        {labels.concat()}
      </Header>
      {days.concat()}
    </Container>
  )
}

const Container = styled.div`
  padding: .5rem;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const NavContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
`

const Nav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

const NavIcon = styled.img`
  height: 1.5rem;
  pointer-events: auto;
`

const YearNav = styled.img`
  height: 1.5rem;
  pointer-events: auto;
`

const CurrentDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-size: 1rem;
  font-weight: 600;
`

const DayName = styled.span`
  font-size: 1rem;
`
