import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { debounce } from "lodash";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { Icons, Images } from "assets";
import { FabStamp, Footer, Loader, Skeleton, Welcome } from "components";
import { useStoreActions, useStoreState } from "stores";
import { AuthStatus, FetchStatus } from "types";
import {
  getStorageLocation,
  isEmpty,
  removeStorageLocation,
  setStorageLocation,
} from "utils";

import { Feature, Outlet, Promotion, Stamp } from "./components";

type Location = {
  lat: string;
  lon: string;
  name: string;
  display_name: string;
  address: {
    city: string;
    city_block: string;
    city_district: string;
    country: string;
    country_code: string;
    county: string;
    house_number: string;
    postcode: string;
    region: string;
    road: string;
    suburb: string;
    village: string;
    state: string;
    town: string;
    municipality: string;
    neighbourhood: string;
    display_name: string;
  };
};

type Result = {
  code: number;
  village: string;
  district: string;
  regency: string;
  province: string;
  latitude: number;
  longitude: number;
  elevation: number;
  timezone: string;
};

export const HomePage: FC = () => {
  const { loggedOut } = useStoreActions((action) => action.auth);
  const { isAuthenticated, authStatus } = useStoreState((state) => state.auth);

  const { fetchProfile } = useStoreActions((action) => action.profile);
  const {
    profile,
    error: profileError,
    status,
  } = useStoreState((state) => state.profile);

  const [welcomeModal, setWelcomeModal] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [location, setLocation] = useState<Location>();
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);

  const [address, setAddress] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [changeLocation, setChangeLocation] = useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<string>("");

  const searchOptions = {
    componentRestrictions: { country: ["id"] },
  };

  const navigate = useNavigate();

  const welcome = localStorage.getItem("MADCOCO_WELCOME_GIFT");

  useEffect(() => {
    setWelcomeModal(welcome === "true");
  }, [welcome]);

  useEffect(() => {
    if (isAuthenticated) fetchProfile();
    else if (authStatus !== AuthStatus.LOADING)
      loggedOut(() => navigate("/login"));
  }, [fetchProfile, isAuthenticated, authStatus, loggedOut, navigate]);

  useEffect(() => {
    if (!isEmpty(profileError)) setError(profileError);
  }, [profileError]);

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getCityName(latitude, longitude);
          setLatitude(latitude);
          setLongitude(longitude);
          setChangeLocation(false);
        },
        (error) => {
          console.error("Error get location: ", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  }, []);

  const getCityName = async (latitude: number, longitude: number) => {
    try {
      setSearchLoading(true);
      const res = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=id`
      );
      setLocation(res.data);
      setSearchLoading(false);
    } catch (e) {
      console.log(e);
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    const currentLocation = async () => {
      const location = await getStorageLocation();
      if (location) {
        const item = JSON.parse(location);
        setLatitude(item.latitude);
        setLongitude(item.longitude);
        setCurrentLocation(item.address);
      } else {
        getLocation();
      }
    };
    currentLocation();
  }, []);

  useEffect(() => {
    setCurrentLocation(
      [
        location?.address?.village,
        location?.address?.suburb,
        location?.address?.municipality,
        location?.address?.county,
        location?.address?.state,
        location?.address?.neighbourhood,
        location?.address?.city_district,
      ]
        .filter((item) => item)
        ?.join(", ")
    );
  }, [location]);

  return (
    <Container>
      {error && <Error>{error}</Error>}
      <Header>
        <Top>
          <Logo src={Images.logoText} alt="logo" />
          <div onClick={() => navigate("/setting")}>
            <Setting src={Icons.setting} alt="icon" />
          </div>
        </Top>
        {currentLocation.length > 0 && (
          <LocationContainer>
            {changeLocation ? (
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={(address) => {
                  geocodeByAddress(address)
                    .then((results) => getLatLng(results[0]))
                    .then((coords) => {
                      setLatitude(coords.lat);
                      setLongitude(coords.lng);
                      setCurrentLocation(address);
                      setChangeLocation(false);
                      setAddress("");
                      const location = {
                        address,
                        latitude: coords.lat,
                        longitude: coords.lng,
                      };
                      setStorageLocation(JSON.stringify(location));
                    })
                    .catch((error) => console.error("Error", error));
                }}
                searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <>
                    <InputContainer>
                      <Input
                        {...getInputProps({
                          placeholder: "Search location ...",
                        })}
                      />
                      <Location
                        onClick={() => {
                          getLocation();
                          setAddress("");
                          removeStorageLocation();
                        }}
                      />
                    </InputContainer>
                    {address && (
                      <ResultLocation>
                        {loading && <ResultList>Loading...</ResultList>}
                        {suggestions.map((suggestion) => {
                          return (
                            <ResultList {...getSuggestionItemProps(suggestion)}>
                              {suggestion.description}
                            </ResultList>
                          );
                        })}
                      </ResultLocation>
                    )}
                  </>
                )}
              </PlacesAutocomplete>
            ) : (
              <LocationWrapper>
                <Pin />
                <CurrentLocation>
                  {searchLoading ? "..." : currentLocation}
                </CurrentLocation>
              </LocationWrapper>
            )}
            <ChangeLocation
              onClick={() => {
                setChangeLocation(!changeLocation);
                setAddress("");
              }}
            >
              {changeLocation ? "Cancel" : "Change"}
            </ChangeLocation>
          </LocationContainer>
        )}
        <Card onClick={() => navigate("/setting")}>
          <CardTop>
            <Profile>
              <ProfileIcon src={Images.logo} />
              {status === FetchStatus.LOADING && !profile?.name ? (
                <Skeleton width={120} />
              ) : (
                <ProfileName>{profile?.name}</ProfileName>
              )}
            </Profile>
            {profile?.card_type?.name && (
              <Badge>{profile.card_type.name}</Badge>
            )}
          </CardTop>
          <CardDetail>
            <PointContainer>
              <Point>
                <PointIcon src={Icons.point} alt="icon" />
                <PointLabel>
                  Points
                  {status === FetchStatus.LOADING && !profile?.point?.redeem ? (
                    <Skeleton width={50} />
                  ) : (
                    <PointValue>
                      {profile?.point?.redeem?.toLocaleString("id-ID") || 0}
                    </PointValue>
                  )}
                </PointLabel>
              </Point>
            </PointContainer>
          </CardDetail>
        </Card>
      </Header>
      <Feature />
      {/* <Stamp /> */}
      <Promotion />
      <Outlet currentLocation={{ latitude, longitude }} />
      {welcomeModal && (
        <Welcome
          title="Welcome to MADCOCO"
          onClick={() => {
            localStorage.removeItem("MADCOCO_WELCOME_GIFT");
            setWelcomeModal(false);
            navigate("/history");
          }}
        />
      )}
      {/* <FabStamp
        onClick={() => navigate('/earn-stamp')}
      /> */}
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  display: block;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Setting = styled.img`
  display: block;
  width: 2rem;
  height: 2rem;
`;

const LocationContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;
`;

const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

const Pin = styled.img.attrs({ src: Icons.pin })`
  height: 1rem;
`;

const CurrentLocation = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--primary-100);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const ChangeLocation = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  color: white;
  background: var(--primary-100);
  border-radius: 1rem;
  padding: 0.4rem 0;
  width: 4rem;
  flex-shrink: 0;
`;

const ResultLocation = styled.div`
  position: absolute;
  top: calc(100% + 0.3rem);
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  max-height: 20rem;
  overflow-y: auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 100;
`;

const ResultList = styled.div`
  padding: 0.5rem 0.8rem;
  font-size: 0.8rem;
  font-weight: 500;
  &:hover {
    background-color: var(--primary-100);
    color: white;
  }
`;

const InputContainer = styled.div`
  position: relative;
  flex: 1;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0.3rem 0.4rem;
  border: var(--border-width) solid var(--grey-30);
  border-radius: var(--radius-xs);
  background-color: var(--surface);
  font-size: 0.8rem;
  color: var(--text-body);
  outline: none;
  transition: opacity 0.2s, color 0.2s, border 0.2s, box-shadow 0.2s,
    background-color 0.2s;

  &:hover {
    background: var(--grey-10);
    color: var(--text-body);
  }

  &:focus {
    border-color: var(--primary-80);
    box-shadow: var(--input-focus-shadow);
    background: var(--grey-10);
    color: var(--text-body);
  }
`;

const SearchLoading = styled.img.attrs({
  src: Icons.loaderColor,
  alt: "loader",
})`
  position: absolute;
  top: 50%;
  right: 0.2rem;
  transform: translateY(-50%);
  height: 1.4rem;
  z-index: 2;
`;

const Location = styled.img.attrs({ src: Icons.location, alt: "icon" })`
  position: absolute;
  top: 50%;
  right: 0.3rem;
  transform: translateY(-50%);
  height: 1rem;
  z-index: 2;
  pointer-events: all;
  opacity: 0.5;
  cursor: pointer;
`;

const Header = styled.div`
  padding: 0.5rem;
  background: #eafff1;
  border-radius: 0 0 2rem 2rem;
`;

const Logo = styled.img`
  display: block;
  height: 1.5rem;
  margin: 0.5rem;
`;

const Card = styled.div`
  margin-top: 0.5rem;
  padding: 0.3rem;
  border-radius: 1.5rem;
  background: var(--primary-100);
`;

const CardDetail = styled.div`
  display: flex;
  padding: 0.4rem;
  border-radius: 1.2rem;
  background: white;
  border: 1px solid #eee;
`;

const CardTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  height: 1.5rem;
  padding: 0 5px;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.8rem;
  font-weight: 800;
  margin-right: 0.5rem;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin: 0.5rem;
`;

const ProfileIcon = styled.img`
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: 0.2rem;
`;

const ProfileName = styled.div`
  font-size: 1rem;
  font-weight: 800;
  color: #fff;
`;

const PointContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  padding: 0.2rem;
`;

const Point = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const PointIcon = styled.img`
  border: 1px solid #eee;
  border-radius: 0.5rem;
  height: 1.8rem;
  padding: 0.2rem;
`;

const PointLabel = styled.div`
  font-size: 0.6rem;
  font-weight: 700;
`;

const PointValue = styled.div`
  font-size: 0.9rem;
  font-weight: 800;
  color: var(--primary-100);
`;

const Error = styled.div`
  font-size: 1rem;
  font-weight: 800;
  color: #fff;
  text-align: center;
  background-color: var(--red-darker);
  padding: 1rem;
`;
