import { useState } from 'react'

import { FormStatus } from 'types'
import { CLIENT_CODE } from 'constant'
import { api } from 'utils'

type RegisterDto = {
  name: string
  mobile_phone: string
  gender: string
  birth_date: string
  password: string
  phone_verification_code: string
}

type UseRegisterNew = {
  status: FormStatus
  submit: (dto: RegisterDto) => Promise<void>
  error: string
}

type Response = {
  status: string
  status_code: number
  message: string
  data: {
    id: number
    code: string
    name: string
    // id_number: string
    profile_picture: string | null
    active: boolean
    total_expense: number
    point: {
      redeem: number
      lucky_draw: number
    }
    api_token: string
  }
}

export const useRegisterNew = (callback: (token: string) => void): UseRegisterNew => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE)
  const [error, setError] = useState('')

  const submit = async (dto: RegisterDto) => {
    try {
      setError('')
      setStatus(FormStatus.LOADING)
      const body = {
        client_code: CLIENT_CODE,
        ...dto
      }
      const res = await api.post<Response>('/register/new', body)
      const { data } = res.data
      callback(data.api_token)
      setTimeout(() => setStatus(FormStatus.SUCCESS), 1000)
    } catch (e: any) {
      setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
      setStatus(FormStatus.ERROR)
    } finally {
      setTimeout(() => setStatus(FormStatus.IDLE), 1)
    }
  }

  return {
    status,
    error,
    submit,
  }
}
