import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'

import { Alert, Button, InputPassword, Topbar } from 'components'
import { FormStatus } from 'types'
import { isEmpty } from 'utils'

import { useChangePassword } from './hooks'

export const ChangePasswordPage: FC = () => {
  const navigate = useNavigate()

  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')

  const { status, message, submit } = useChangePassword({
    onError: (message: string) => setError(message)
  })

  const ForgotSchema = yup.object().shape({
    oldPassword: yup.string().required('Old Password is required'),
    newPassword: yup
      .string()
      .required('New Password is required')
      .matches(/^(?=[^A-Za-z]*[A-Za-z])(?=.*\d).{8,}$/, 'The password must be at least 8 characters, a combination of letters and numbers'),
    newPasswordConfirmation: yup
      .string()
      .required('New Password Confirmation is required')
      .matches(/^(?=[^A-Za-z]*[A-Za-z])(?=.*\d).{8,}$/, 'The password must be at least 8 characters, a combination of letters and numbers')
      .oneOf([yup.ref('newPassword')], 'Passwords must match')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ForgotSchema,
    onSubmit: values => submit({ old_password: values.oldPassword, new_password: values.newPassword })
  })

  useEffect(() => {
    if (!isEmpty(message)) setSuccess(message)
  }, [message])

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <Title>CHANGE PASSWORD</Title>
      <Form onSubmit={handleSubmit}>
        <InputPassword
          name="oldPassword"
          label="Old Password"
          value={values.oldPassword}
          onChange={handleChange}
          error={errors.oldPassword}
        />
        <InputPassword
          name="newPassword"
          label="New Password"
          value={values.newPassword}
          onChange={handleChange}
          error={errors.newPassword}
        />
        <InputPassword
          name="newPasswordConfirmation"
          label="New Password Confirmation"
          value={values.newPasswordConfirmation}
          onChange={handleChange}
          error={errors.newPasswordConfirmation}
        />
        <Button
          type="submit"
          label="Save"
          loading={status === FormStatus.LOADING}
        />
      </Form>
      {!isEmpty(error) &&
        <Alert
          title="Reset Password Failed"
          message={error}
          onClick={() => setError('')}
        />
      }
      {!isEmpty(success) &&
        <Alert
          title="Change Password"
          message={success}
          onClick={() => {
            setSuccess('')
            navigate(-1)
          }}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  display: block;
  padding: 1rem;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const Form = styled.form`
  margin-top: 2rem;
`