import { api, getAccessToken } from 'utils'
import { PaginationData } from 'types'

import { GetRewardsParams, Reward } from '.'
import { CLIENT_CODE } from 'constant'

export const fetchUsed = async (
  params: GetRewardsParams
): Promise<PaginationData<Reward>> => {
  const accessToken = await getAccessToken()
  try {
    const res = await api.get<PaginationData<Reward>>(
      '/customer/vouchers',
      {
        params: {
          access_token: accessToken,
          limit: params.paginator.limit,
          page: params.paginator.page,
          status: params.status,
          code_client: CLIENT_CODE
        }
      }
    )
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const fetchUnused = async (
  params: GetRewardsParams
): Promise<PaginationData<Reward>> => {
  const accessToken = await getAccessToken()
  try {
    const res = await api.get<PaginationData<Reward>>(
      '/customer/vouchers',
      {
        params: {
          access_token: accessToken,
          limit: params.paginator.limit,
          page: params.paginator.page,
          status: params.status,
          code_client: CLIENT_CODE
        }
      }
    )
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

