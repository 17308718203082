import { FC, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-spring-bottom-sheet/dist/style.css'

import { Background, Container } from 'components'
import { BenefitCardPage, ChangePasswordPage, ContactUsPage, ConvertPage, EditProfilePage, ForgotPasswordPage, HistoryPage, HomePage, LoginPage, OtpPage, OutletDetailPage, OutletPage, ProfilePage, PromoDetailPage, PromoPage, RegisterPage, RewardPage, SettingPage, StampCollabPage, StampPage, VoucherDetailPage, VoucherPage } from 'pages'

import { useStoreActions, useStoreState } from 'stores'
import { AuthStatus } from 'types'

export const App: FC = () => {
  const location = useLocation()

  const { isAuthenticated, authStatus } = useStoreState(state => state.auth)
  const { checkAuth } = useStoreActions(action => action.auth)

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  const [displayLocation, setDisplayLocation] = useState(location)
  const [transitionStage, setTransistionStage] = useState<string>('fadeIn')

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage('fadeOut')
  }, [location, displayLocation])

  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === '/') {
      if (isAuthenticated) navigate('/home')
      else if (authStatus !== AuthStatus.LOADING) navigate('/login')
    }
  }, [isAuthenticated, authStatus, navigate, location])

  return (
    <>
      <Background />
      <div
        className={`${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === 'fadeOut') {
            setTransistionStage('fadeIn')
            setDisplayLocation(location)
          }
        }}
      >
        <Container>
          <Routes location={displayLocation}>
            <Route path='/' element={<div />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/register' element={<RegisterPage />} />
            <Route path='/register/otp' element={<OtpPage />} />
            <Route path='/forgot-password' element={<ForgotPasswordPage />} />
            <Route path='/home' element={<HomePage />} />
            <Route path='/card' element={<BenefitCardPage />} />
            <Route path='/convert-point' element={<ConvertPage />} />
            <Route path='/voucher' element={<VoucherPage />} />
            <Route path='/voucher/:id' element={<VoucherDetailPage />} />
            <Route path='/reward' element={<RewardPage />} />
            <Route path='/stamp' element={<StampPage />} />
            <Route path='/earn-stamp' element={<StampCollabPage />} />
            <Route path='/history' element={<HistoryPage />} />
            <Route path='/promo' element={<PromoPage />} />
            <Route path='/promo/:id' element={<PromoDetailPage />} />
            <Route path='/outlet' element={<OutletPage />} />
            <Route path='/outlet-detail' element={<OutletDetailPage />} />
            <Route path='/setting' element={<SettingPage />} />
            <Route path='/profile' element={<ProfilePage />} />
            <Route path='/edit-profile' element={<EditProfilePage />} />
            <Route path='/change-password' element={<ChangePasswordPage />} />
            <Route path='/contact-us' element={<ContactUsPage />} />
            <Route path="*" element={<div>404</div>} />
          </Routes>
        </Container>
      </div>
    </>
  )
}
