import { FC, InputHTMLAttributes, useEffect, useRef } from 'react'
import styled from 'styled-components'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
}

export const InputEmail: FC<Props> = ({ label, error, ...props }) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (error) ref.current?.setAttribute('data-error', 'true')
    else ref.current?.removeAttribute('data-error')
  }, [error])

  return (
    <Container>
      {label &&
        <LabelContainer>
          <Label>{label}</Label>
        </LabelContainer>
      }
      <Input
        ref={ref}
        type="email"
        placeholder={props.placeholder}
        autoComplete={props.name}
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        {...props}
      />
      {error &&
        <Error>
          {error}
        </Error>
      }
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: var(--token-12);
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.label`
  display: block;
  margin-bottom: var(--token-08);
  color: var(--secondary-100);
  font-weight: var(--fw-medium);
`

const Input = styled.input`
  display: block;
  width: 100%;
  min-height: 42px;
  padding: var(--input-padding);
  border: var(--border-width) solid var(--grey-30);
  border-radius: var(--radius-xs);
  background-color: var(--surface);
  color: var(--text-body);
  outline: none;
  transition: opacity .2s, color .2s, border .2s, box-shadow .2s, background-color .2s;

  &:hover {
    background: var(--grey-10);
    color: var(--text-body);
  }

  &:focus {
    border-color: var(--primary-80);
    box-shadow: var(--input-focus-shadow);
    background: var(--grey-10);
    color: var(--text-body);
  }

  &[data-error=true] {
    border-color: var(--red-medium);
    background: var(--red-lightest);
    color: var(--red-dark);
    box-shadow: var(--input-error-shadow);
  }
`

const Error = styled.div`
  color: var(--red-dark);
  height: auto;
  opacity: 1;
  pointer-events: auto;
  overflow: visible;
  padding: var(--token-04) 0 0;
  font-size: var(--font-size-sm);
`
