import { api } from 'utils'

import {
  GetAllPromoResponse,
  GetAllPromoParams,
  PromoService
} from '.'

const getAllPromo = async (
  params: GetAllPromoParams,
): Promise<GetAllPromoResponse> => {
  try {
    const res = await api.get<GetAllPromoResponse>('/promotion', { params })
    return res.data
  } catch (e) {
    throw e
  }
}

export const promoService: PromoService = {
  getAllPromo
}
