import { FC } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick'
import moment from 'moment'
import styled from 'styled-components'

import { CLIENT_CODE } from 'constant'
import { Topbar } from 'components'

import { usePromotionDetail } from './hooks'
import { PromoItem } from 'stores'

export const PromoDetailPage: FC = () => {
  const { id } = useParams()
  const location = useLocation()

  const item: PromoItem = location?.state?.item

  const { data } = usePromotionDetail(id, CLIENT_CODE)

  const navigate = useNavigate()

  const promo = item || data

  const settings = {
    dots: false,
    infinite: promo?.photos?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <Slider {...settings}>
        {promo?.photos.map((item, index) =>
          <Banner
            key={index}
            src={item}
            alt="banner"
          />
        )}
      </Slider>
      <Content>
        <Title>{promo?.name}</Title>
        <Outlet>
          Outlet: <strong>{promo?.store?.name}</strong>
        </Outlet>
        <ValidUntil>
          Valid until: <strong>{moment(promo?.period?.end).format('DD MMMM YYYY')}</strong>
        </ValidUntil>
        <Description
          dangerouslySetInnerHTML={{ __html: promo?.description || '' }}
        />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: block;
`

const Banner = styled.img`
  display: block !important;
  width: 100%;
  aspect-ratio: 1;
`

const Content = styled.div`
  padding: 1rem;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 1px;
`

const Outlet = styled.div`
  font-size: .8rem;
  font-weight: 500;
  margin: .5rem 0;
`

const ValidUntil = styled.div`
  font-size: .8rem;
  font-weight: 400;
  margin: .5rem 0;
`

const Description = styled.div`
  font-size: .8rem;
  font-weight: 400;
  margin-top: 2rem;
`
