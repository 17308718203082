import { FC, Fragment, useEffect, useRef, useState, } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { Icons } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'

export const Fab: FC = () => {
  const { fetchAllClient } = useStoreActions(action => action.client)
  const { clients } = useStoreState(state => state.client)

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    fetchAllClient()
  }, [fetchAllClient])

  const CONTACT_NUMBER = clients.find(client => client.code === CLIENT_CODE)?.whatsapp_number?.replace(/[-+ ]/g, '') || ''
  const IG = clients.find(client => client.code === CLIENT_CODE)?.instagram_link || ''

  let offsetX: number
  let offsetY: number
  let isMove: boolean

  const ref = useRef<HTMLDivElement>(null)
  const overlay = useRef<HTMLDivElement>(null)

  const move = (e: MouseEvent) => {
    const el = e.target as HTMLDivElement
    el.style.left = `${e.pageX - offsetX}px`
    el.style.top = `${e.pageY - offsetY}px`
    isMove = true
    overlay.current!.style!.backgroundColor = 'rgba(0,0,0,.5)'
  }

  const add = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    const el = e.target as HTMLDivElement
    offsetX = e.clientX - el.getBoundingClientRect().left + window.scrollX
    offsetY = e.clientY - el.getBoundingClientRect().top + window.scrollY
    if (!open) el.addEventListener('mousemove', move)
  }

  const remove = (e: React.MouseEvent<HTMLElement>): void => {
    const el = e.target as HTMLDivElement
    snap()
    el.removeEventListener('mousemove', move)
  }

  const moveTouch = (e: TouchEvent) => {
    e.preventDefault()
    const el = e.target as HTMLDivElement
    el.style.left = `${e.touches[0].pageX - offsetX}px`
    el.style.top = `${e.touches[0].pageY - offsetY}px`
    overlay.current!.style!.backgroundColor = 'rgba(0,0,0,.5)'
  }

  const addTouch = (e: React.TouchEvent<HTMLElement>): void => {
    e.preventDefault()
    const el = e.target as HTMLDivElement
    offsetX = e.touches[0].clientX - el.getBoundingClientRect().left + window.scrollX
    offsetY = e.touches[0].clientY - el.getBoundingClientRect().top + window.scrollY

    el.addEventListener('touchmove', moveTouch)
  }

  const removeTouch = (e: React.TouchEvent<HTMLElement>): void => {
    const el = e.target as HTMLDivElement
    snap()
    el.removeEventListener('touchmove', moveTouch)
  }

  const snap = () => {
    if (ref.current) {
      const snapTo = ((ref.current?.offsetLeft + (ref.current?.offsetWidth / 2)) < window.innerWidth / 2) ? 'left' : 'right'
      const top = ref.current?.offsetTop
      ref.current!.style!.transition = 'all .5s ease'
      if (snapTo === 'right') ref.current!.style!.left = `${window.innerWidth - ref.current?.offsetWidth - 16}px`
      else ref.current!.style!.left = '16px'
      if (top < 16) ref.current!.style!.top = '16px'
      if (top + ref.current?.offsetHeight > window.innerHeight) ref.current!.style!.top = `${window.innerHeight - ref.current?.offsetHeight - 16}px`
      setTimeout(() => ref.current!.style!.transition = 'none', 500)
    }
    setTimeout(() => isMove = false, 500)
    overlay.current!.style!.backgroundColor = 'unset'
  }
  return (
    <Fragment>
      {createPortal(
        <Overlay ref={overlay}>
          <Container
            ref={ref}
            onMouseDown={add}
            onMouseUp={remove}
            onMouseLeave={remove}
            onTouchStart={addTouch}
            onTouchEnd={removeTouch}
            onClick={() => !isMove && setOpen(!open)}
          >
            <Image
              src={open ? Icons.close : Icons.cs}
              alt="loader"
            />
            <SocialMedia>
              <Whatsapp
                $active={open}
                onClick={() => {
                  setOpen(false)
                  window.open(`https://wa.me/${CONTACT_NUMBER}`)
                }}
              />
              <Instagram
                $active={open}
                onClick={() => {
                  setOpen(false)
                  window.open(IG)
                }}
              />
              <Tiktok
                $active={open}
                onClick={() => {
                  setOpen(false)
                  window.open('https://www.tiktok.com/@madcoco.id')
                }}
              />
            </SocialMedia>
          </Container>
        </Overlay>,
        document.body
      )}
    </Fragment>
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000001;
  pointer-events: none;
`

const Container = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  pointer-events: all;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  z-index: 2;
  cursor: pointer;
`

const Image = styled.img`
  position: relative;
  height: 100%;
  background-color: var(--primary-100);
  border-radius: 50%;
  padding: .5rem;
  transition: all .3s ease;
  z-index: 2;
`

const SocialMedia = styled.div`
  position: absolute;
  width: 100%;
  bottom: 3rem;
  right: 0;
  pointer-events: none;
`

const Whatsapp = styled.img.attrs({ src: Icons.whatsapp }) <{ $active: boolean }>`
  width: 100%;
  transition: all .3s ease;
  transform: translateY(${props => props.$active ? '0' : '9rem'}) scale(${props => props.$active ? 1 : 0});
  opacity: ${props => props.$active ? 1 : 0};
  background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 50%;
  pointer-events: all;
  cursor: pointer;
`

const Instagram = styled.img.attrs({ src: Icons.instagram }) <{ $active: boolean }>`
  width: 100%;
  transition: all .3s ease;
  transform: translateY(${props => props.$active ? '0' : '6rem'}) scale(${props => props.$active ? 1 : 0});
  opacity: ${props => props.$active ? 1 : 0};
  background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 50%;
  pointer-events: all;
  cursor: pointer;
`

const Tiktok = styled.img.attrs({ src: Icons.tiktok }) <{ $active: boolean }>`
  width: 100%;
  transition: all .3s ease;
  transform: translateY(${props => props.$active ? '0' : '3rem'}) scale(${props => props.$active ? 1 : 0});
  opacity: ${props => props.$active ? 1 : 0};
  background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 50%;
  pointer-events: all;
  cursor: pointer;
`
