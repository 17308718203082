import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'

import { Alert, Button, InputDate, InputPassword, InputPhone, InputText, Topbar } from 'components'
import { useStoreState } from 'stores'
import { FormStatus } from 'types'
import { RegisterDto, useRegisterValidate } from './hooks'
import { isEmpty } from 'utils'

export const RegisterPage: FC = () => {
  const navigate = useNavigate()

  const [data, setData] = useState<RegisterDto>()
  const [error, setError] = useState<string>('')
  const { isAuthenticated } = useStoreState(state => state.auth)

  const validate = useRegisterValidate()

  useEffect(() => {
    if (isAuthenticated) navigate('/home')
  }, [isAuthenticated, navigate])

  const RegisterSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    mobile_phone: yup.string().required('Phone Number is required'),
    gender: yup.string().matches(/^M|F$/, 'Gender is required'),
    birth_date: yup.string().required('Birth date is required'),
    password: yup
      .string()
      .required('Password is required')
      .matches(/^(?=[^A-Za-z]*[A-Za-z])(?=.*\d).{8,}$/, 'The password must be at least 8 characters, a combination of letters and numbers'),
    passwordConfirmation: yup
      .string()
      .required('Password Confirmation is required')
      .matches(/^(?=[^A-Za-z]*[A-Za-z])(?=.*\d).{8,}$/, 'The password must be at least 8 characters, a combination of letters and numbers')
      .oneOf([yup.ref('password')], 'Passwords must match')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      name: '',
      mobile_phone: '',
      gender: '',
      birth_date: '',
      password: '',
      passwordConfirmation: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: RegisterSchema,
    onSubmit: values => {
      setData(values)
      validate.submit(values)
    }
  })

  useEffect(() => {
    if (validate.status === FormStatus.ERROR) {
      setError(validate.error)
    }
    if (validate.status === FormStatus.SUCCESS) {
      navigate('otp', { state: { data } })
    }
  }, [validate.status, validate.error, navigate])

  return (
    <Container>
      <Topbar onBack={() => navigate(-1)} />
      <Title>CREATE ACCOUNT</Title>
      <Form onSubmit={handleSubmit}>
        <InputText
          name="name"
          label="Name"
          value={values.name}
          onChange={handleChange}
          error={errors.name}
        />
        <InputPhone
          name="mobile_phone"
          label="Phone Number"
          value={values.mobile_phone}
          onChange={e => handleChange('mobile_phone')(e.target.value.replace(/[+-\s]/g, ''))}
          error={errors.mobile_phone}
        />
        <GenderContainer>
          <LabelContainer>
            <Label>Gender</Label>
          </LabelContainer>
          <RadioGroup>
            <RadioContainer>
              <GenderOption
                id="male"
                value="M"
                checked={values.gender === 'M'}
                onChange={e => handleChange('gender')(e.target.value)}
              />
              <label htmlFor="male">Male</label>
            </RadioContainer>
            <RadioContainer>
              <GenderOption
                id="female"
                value="F"
                checked={values.gender === 'F'}
                onChange={e => handleChange('gender')(e.target.value)}
              />
              <label htmlFor="female">Female</label>
            </RadioContainer>
          </RadioGroup>
          <Error>{errors.gender}</Error>
        </GenderContainer>
        <InputDate
          label="Birth Date"
          value={values.birth_date}
          onChangeDate={date => handleChange('birth_date')(date)}
          error={errors.birth_date}
        />
        <InputPassword
          name="password"
          label="Password"
          value={values.password}
          onChange={handleChange}
          error={errors.password}
        />
        <InputPassword
          name="passwordConfirmation"
          label="Password Confirmation"
          value={values.passwordConfirmation}
          onChange={handleChange}
          error={errors.passwordConfirmation}
        />
        <Button
          type="submit"
          label="Register"
          loading={validate.status === FormStatus.LOADING}
        />
      </Form>
      {!isEmpty(error) &&
        <Alert
          title="Create Account"
          message={error}
          onClick={() => setError('')}
        />
      }
    </Container>
  )
}

const Container = styled.div`
  display: block;
  padding: 1rem;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const Form = styled.form`
  margin-top: 2rem;
`

const GenderContainer = styled.div`
  padding-bottom: var(--token-12);
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.label`
  display: block;
  margin-bottom: var(--token-08);
  color: var(--secondary-100);
  font-weight: var(--fw-medium);
`

const RadioGroup = styled.div`
  display: flex;
  gap: 2rem;
`

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
  margin: .5rem 0;
`

const GenderOption = styled.input.attrs({ type: 'radio', name: 'gender' })`
  accent-color: var(--primary-100);
  width: 1.2rem;
  height: 1.2rem;
`

const Error = styled.div`
  color: var(--red-dark);
  height: auto;
  opacity: 1;
  pointer-events: auto;
  overflow: visible;
  padding: var(--token-04) 0 0;
  font-size: var(--font-size-sm);
`