import { STORAGE_ACCESS_KEY, STORAGE_LOCATION } from 'constant'

export const persistAccessToken = async (token: string): Promise<void> => {
  return localStorage.setItem(STORAGE_ACCESS_KEY, token)
}

export const destroyAccessToken = async (): Promise<void> => {
  return localStorage.removeItem(STORAGE_ACCESS_KEY)
}

export const getAccessToken = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_ACCESS_KEY)
}

export const setStorageLocation = async (location: string): Promise<void> => {
  return localStorage.setItem(STORAGE_LOCATION, location)
}

export const removeStorageLocation = async (): Promise<void> => {
  return localStorage.removeItem(STORAGE_LOCATION)
}

export const getStorageLocation = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_LOCATION)
}
