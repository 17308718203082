import { useState } from 'react'

import { api } from 'utils'
import { useStoreActions } from 'stores'
import { AxiosError } from 'axios'

export interface EditProfileDto {
  profile_picture?: File | null
  name: string
  mobile_phone: string
  gender: string
  birth_date: string
}

export interface UseEditProfile {
  isLoading: boolean
  submit: (dto: EditProfileDto) => Promise<void>
}

export const useEditProfile = (
  setSuccess: (message: string) => void,
  setError: (error: string) => void
): UseEditProfile => {
  const [isLoading, setIsLoading] = useState(false)
  const { refreshProfile } = useStoreActions((action) => action.profile)

  const submit = async (dto: EditProfileDto | any): Promise<void> => {
    try {
      setIsLoading(true)

      const formData = new FormData()

      for (const key in dto) {
        if (dto[key] && key !== 'mobile_phone') formData.append(key, dto[key])
      }

      const res = await api.post('/customer',
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: formData => formData
      })

      setSuccess(res?.data?.message)
      refreshProfile()
      setIsLoading(false)
    } catch (e) {
      if (e instanceof AxiosError) {
        setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        setIsLoading(false)
      }
    }
  }

  return {
    isLoading,
    submit
  }
}
