import { AxiosError } from 'axios'
import { useState } from 'react'
import { AuthResponse } from 'stores'

import { FormStatus } from 'types'
import { api } from 'utils'

export const useActivateCustomer = () => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE)
  const [error, setError] = useState<string>('')
  const [data, setData] = useState<AuthResponse>()

  const submit = async (code_client: string) => {
    try {
      setStatus(FormStatus.LOADING)
      const body = {
        code_client
      }
      const res = await api.post('/customer/login', body)
      setStatus(FormStatus.SUCCESS)
      setData(res?.data?.data)
    } catch (e) {
      if (e instanceof AxiosError) {
        setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        setStatus(FormStatus.ERROR)
      }
    } finally {
      setTimeout(() => setStatus(FormStatus.IDLE), 1)
    }
  }

  return { status, data, error, submit }
}
