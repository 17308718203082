import { FC, InputHTMLAttributes } from 'react'
import InputMask from 'react-input-mask'
import styled from 'styled-components'

import { isEmpty } from 'utils'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name?: string
  label?: string
  placeholder?: string
  error?: string
}

export const InputPhone: FC<Props> = ({ label, error, ...props }) => {
  return (
    <Container>
      {label &&
        <LabelContainer>
          <Label>{label}</Label>
        </LabelContainer>
      }
      <InputMask
        className="input-phone"
        type="tel"
        mask="+62 999-9999-9999-99"
        maskPlaceholder={null}
        name="phone"
        beforeMaskedStateChange={({ nextState, currentState, previousState }) => {
          let { value } = nextState
          if (value.endsWith('-')) value = value.slice(0, -1)
          if (value.charAt(4) === '0') value = `${value.slice(0, 4)}${value.slice(5)}`
          if (value.slice(4, 6) === '62') value = `${value.slice(0, 4)}${value.slice(6)}`
          if (currentState?.value?.startsWith('0')) {
            const phone = `+62${currentState?.value.slice(1)}`
            value = `${phone.slice(0, 3)} ${phone.slice(3, 6)}-${phone.slice(6, 10)}-${phone.slice(10, 14)}${phone.length > 14 ? `-${phone.slice(14, 16)}` : ''}`
            nextState.selection!.start = value.length
            nextState.selection!.end = value.length
          }
          if (currentState?.value?.slice(0, 2) === '62') {
            const phone = `+${currentState?.value}`
            value = `${phone.slice(0, 3)} ${phone.slice(3, 6)}-${phone.slice(6, 10)}-${phone.slice(10, 14)}${phone.length > 14 ? `-${phone.slice(14, 16)}` : ''}`
            nextState.selection!.start = value.length
            nextState.selection!.end = value.length
          }

          return {
            ...nextState,
            value
          }
        }}
        data-error={!isEmpty(error)}
        autoCorrect="off"
        autoComplete="off"
        autoCapitalize="off"
        spellCheck="false"
        {...props}
      />
      {error &&
        <Error>
          {error}
        </Error>
      }
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: var(--token-12);
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.label`
  display: block;
  margin-bottom: var(--token-08);
  color: var(--secondary-100);
  font-weight: var(--fw-medium);
`

const Error = styled.div`
  color: var(--red-dark);
  height: auto;
  opacity: 1;
  pointer-events: auto;
  overflow: visible;
  padding: var(--token-04) 0 0;
  font-size: var(--font-size-sm);
`
