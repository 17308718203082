import { FC, InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
  onForgot?: () => void
}

export const InputPassword: FC<Props> = ({ label, error, onForgot, ...props }) => {
  const ref = useRef<HTMLInputElement>(null)

  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    if (error) ref.current?.setAttribute('data-error', 'true')
    else ref.current?.removeAttribute('data-error')
  }, [error])

  return (
    <Container>
      {label &&
        <LabelContainer>
          <Label>{label}</Label>
          {onForgot &&
            <Forgot onClick={onForgot}>
              Forgot?
            </Forgot>
          }
        </LabelContainer>
      }
      <InputContainer>
        <Input
          ref={ref}
          type={show ? 'text' : 'password'}
          autoComplete={props.name || 'current-password'}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          $font={show ? 'Outfit' : 'Source Code Pro'}
          {...props}
        />
        <Eye
          onClick={() => setShow(!show)}
        >
          <svg width={35} height={35} viewBox="-1 0 24 17">
            <g>
              <Path d="M17,7.5c-0.8-1-3.6-4.2-7-4.2c-3.5,0-6.3,3.2-7,4.2c-0.2,0.3-0.2,0.7,0,1c0.8,1,3.6,4.2,7,4.2s6.3-3.2,7-4.2 C17.2,8.2,17.2,7.8,17,7.5z M10,11.7c-2.9,0-5.5-2.8-6.2-3.7C4.5,7.2,7.1,4.3,10,4.3s5.5,2.8,6.2,3.7C15.5,8.8,12.9,11.7,10,11.7z" />
              <Path d="M10,5.2C8.4,5.2,7.2,6.4,7.2,8s1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8S11.6,5.2,10,5.2z M10,9.9C9,9.9,8.1,9,8.1,8 S9,6.1,10,6.1S11.9,7,11.9,8S11,9.9,10,9.9z" />
            </g>
            <Line x1="4.4" y1="12.5" x2="15.7" y2="3.2" data-show={show} />
          </svg>
        </Eye>
      </InputContainer>
      {error &&
        <Error>
          {error}
        </Error>
      }
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: var(--token-12);
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.label`
  display: block;
  margin-bottom: var(--token-08);
  color: var(--secondary-100);
  font-weight: var(--fw-medium);
`

const Forgot = styled.span`
  display: block;
  font-weight: var(--fw-medium);
  margin-bottom: var(--token-08);
  color: var(--primary);
  cursor: pointer;
`

const InputContainer = styled.div`
  position: relative;
  font-size: 15px;
`

const Input = styled.input<{ $font?: string }>`
  display: block;
  width: 100%;
  min-height: 42px;
  padding: var(--input-padding);
  padding-right: 40px;
  border: var(--border-width) solid var(--grey-30);
  border-radius: var(--radius-xs);
  background-color: var(--surface);
  font-family: ${props => props.$font}, monospace;
  letter-spacing: 1px;
  color: var(--text-body);
  outline: none;
  caret-color: var(--primary-100);
  transition: opacity .2s, color .2s, border .2s, box-shadow .2s, background-color .2s;

  &:hover {
    background: var(--grey-10);
  }

  &:focus {
    border-color: var(--primary-80);
    box-shadow: var(--input-focus-shadow);
    background: var(--grey-10);
  }

  &[data-error=true] {
    border-color: var(--red-medium);
    background: var(--red-lightest);
    box-shadow: var(--input-error-shadow);
  }
`

const Eye = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Path = styled.path`
  fill: var(--primary-100);
`

const Line = styled.line`
  fill: none;
  stroke: var(--primary-100);
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 20px 29px;
  stroke-dashoffset: 0;
  transition: .3s ease-in-out;
  &[data-show=true] {
    stroke-dashoffset: 20px;
  }
`

const Error = styled.div`
  color: var(--red-dark);
  height: auto;
  opacity: 1;
  pointer-events: auto;
  overflow: visible;
  padding: var(--token-04) 0 0;
  font-size: var(--font-size-sm);
`
