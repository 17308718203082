import { action, thunk } from 'easy-peasy'
import { AxiosError } from 'axios'

import { FormStatus } from 'types'

import { Redeem, RedeemModel, redeemService } from '.'

export const redeem: RedeemModel = {
  data: {} as Redeem,
  status: FormStatus.IDLE,
  error: '',

  // Actions
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  submit: thunk(async (action, payload) => {
    try {
      action.setStatus(FormStatus.LOADING)

      const res = await redeemService.redeem(payload)
      action.setStatus(FormStatus.SUCCESS)
      action.setData(res.data)
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setStatus(FormStatus.ERROR)
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
      }
    } finally {
      setTimeout(() => {
        action.setStatus(FormStatus.IDLE)
        action.setError('')
      }, 10)
    }
  })
}
